import React, { useEffect } from 'react';
import { FaSign, FaBuilding, FaUser } from 'react-icons/fa';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import Banner from '../../images/Sample1.png'; // Ensure the path is correct
import BannerImg from '../../images/hoarding 2 .png'; // Ensure the path is correct
import BannerImg1 from '../../images/hoarding 3.jpg'; // Ensure the path is correct
import BannerImg2 from '../../images/hoarding 2 .png'; // Ensure the path is correct
import Digital from '../../images/02-pak.png';
import Lit from '../../images/01-pak.png';
import Unlit from '../../images/Unlit.png';
import Streamer from '../../images/streamer.jpeg';
import { Link } from 'react-router-dom';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

const About = ({ setProgress }) => {

    // For Loader
    useEffect(() => {
        setProgress(40);

        setTimeout(() => {
            setProgress(80);
        }, 1000);
        setTimeout(() => {
            setProgress(100);
        }, 2000);

    }, [setProgress]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        cssEase: 'ease-in-out',
        // prevArrow: <></>,
        // nextArrow: <></>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <div className="relative w-full h-1/4 font-abc scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700">
                <div className="absolute inset-0 bg-black opacity-60 z-10"></div>
                <img
                    src={Banner}
                    alt="Add Slider"
                    className="absolute inset-0 w-full h-full object-cover z-0"
                />
                <section className="relative z-20 flex items-center justify-center w-full h-full">
                    <div className="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-36">
                        <p className="mt-2 font-bold text-4xl text-white lg:text-md sm:px-16 lg:px-48">
                            About Us
                        </p>
                        <div className="mx-auto mt-1 bg-blue-500 h-1 w-36"></div>
                        <p className="mt-2 font-normal text-white lg:text-md sm:px-16 lg:px-48">
                            <nav
                                className="flex mx-auto max-w-screen-xl px-4 font-abc mt-3 ml-4"
                                aria-label="Breadcrumb"
                            >
                                <ol className="inline-flex items-center space-x-1 md:space-x-2">
                                    <li className="inline-flex items-center">
                                        <Link
                                            to="/"
                                            className="inline-flex items-center text-sm font-medium text-white hover:text-blue-400"
                                        >
                                            <svg
                                                className="w-3 h-3 mr-2.5"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                            >
                                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 1-1h2a1 1 0 1-1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                            </svg>
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="flex items-center">
                                            <svg
                                                className="w-3 h-3 text-gray-400 mx-1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 6 10"
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="m1 9 4-4-4-4"
                                                />
                                            </svg>
                                            <Link

                                                className="text-sm font-medium text-white hover:text-blue-400"
                                            >
                                                Items
                                            </Link>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </p>
                    </div>
                </section>
            </div>


            <div className="relative mt-16">

                <div className="relative w-11/12 lg:w-10/12 xl:w-[1200px] m-auto">
                    <div className="space-y-16">
                        <div className="w-full max-w-[700px] mx-auto space-y-8 md:space-y-0 text-center md:flex md:justify-center md:items-center">
                            <div className="w-full space-y-4">
                                <h3 className="text-xl font-bold">
                                    <span className="text-[#2a2968]">Welcome to Pak Hoardings </span>
                                </h3>

                                <h3 className="font-bold text-4xl">
                                    <span className=" text-blue-500">Premier Platform Where Brands meet Hoarding Owners.</span>
                                </h3>
                                <p className="text-gray-600 text-lg font-medium text-start">
                                    At Pak Hoardings, brands can easily approach hoarding owners to secure prime advertising spaces. Our platform simplifies connections between advertisers and hoarding owners, ensuring a smooth experience in finding ideal locations.
                                </p>
                            </div>
                        </div>


                        <div className="bg-white dark:bg-gray-800 h-full sm:py-8 lg:py-6">
                            <h2 className="text-center text-4xl font-bold text-gray-900 leading-[3.25rem] mb-6 ">
                                Billboard
                                <span className=' bg-clip-text text-blue-500'> Categories</span>
                            </h2>

                            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                                <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:gap-6 xl:gap-8">
                                    <a className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80">
                                        <img src={Unlit} loading="lazy" alt="Unlit Billboard" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                        <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                                        <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-xl">Unlit Billboard</span>
                                    </a>

                                    <a className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80">
                                        <img src={Lit} loading="lazy" alt="Lit Billboard" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                        <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                                        <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-xl">Lit Billboard</span>
                                    </a>

                                    <a className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80">
                                        <img src={Digital} loading="lazy" alt="Digital Billboard" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                        <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                                        <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-xl">Digital Billboard</span>
                                    </a>

                                    <a className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80">
                                        <img src={Streamer} loading="lazy" alt="Digital Streamer" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                                        <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                                        <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-xl">Digital Streamer</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="relative text-center mt-20 mb-14">
                    <div className="relative text-center mt-14 mb-8 mx-auto w-full">
                        <h2 className="text-center text-4xl font-bold text-gray-900 leading-[3.25rem] mb-20">
                            How It
                            <span className='bg-clip-text text-blue-500'> Works</span>
                        </h2>
                        <div className="flex flex-col md:flex-row justify-center items-start">
                            {/* Step 1: Hoarding Owners */}
                            <div className="relative flex flex-col items-center bg-[#E6F7FF] rounded-lg p-8 mb-4 md:mb-0 mx-2 w-full md:w-[20%]"> {/* Changed width for responsiveness */}
                                <div className="absolute -top-12 bg-[#007BFF] rounded-full w-24 h-24 flex items-center justify-center border-4 border-white">
                                    <FaSign size={40} className="text-white" /> {/* Signboard Icon */}
                                </div>
                                <h4 className="mt-16 text-xl font-semibold text-[#1C64F2]">
                                    Hoarding Owners
                                </h4>
                                <ul className="text-gray-600 mt-4 text-sm space-y-2 text-left">
                                    <li className="flex items-center">
                                        <span className="bg-[#007BFF] w-2 h-2 rounded-full mr-2"></span>
                                        Register Pakhoardings
                                    </li>
                                    <li className="flex items-center">
                                        <span className="bg-[#007BFF] w-2 h-2 rounded-full mr-2"></span>
                                        List Hoarding
                                    </li>
                                    <li className="flex items-center">
                                        <span className="bg-[#007BFF] w-2 h-2 rounded-full mr-2"></span>
                                        Set Availability and Pricing
                                    </li>
                                    <li className="flex items-center">
                                        <span className="bg-[#007BFF] w-2 h-2 rounded-full mr-2"></span>
                                        Start Earning
                                    </li>
                                </ul>
                            </div>

                            {/* Step 2: Brand Owners */}
                            <div className="relative flex flex-col items-center bg-[#E6F7FF] rounded-lg p-8 mb-4 md:mb-0 mx-2 w-full md:w-[20%]"> {/* Changed width for responsiveness */}
                                <div className="absolute -top-12 bg-[#007BFF] rounded-full w-24 h-24 flex items-center justify-center border-4 border-white">
                                    <FaBuilding size={40} className="text-white" /> {/* Building Icon */}
                                </div>
                                <h4 className="mt-16 text-xl font-semibold text-[#1C64F2]">
                                    Brand Owners
                                </h4>
                                <ul className="text-gray-600 mt-4 text-sm space-y-2 text-left">
                                    <li className="flex items-center">
                                        <span className="bg-[#007BFF] w-2 h-2 rounded-full mr-2"></span>
                                        Browse Locations
                                    </li>
                                    <li className="flex items-center">
                                        <span className="bg-[#007BFF] w-2 h-2 rounded-full mr-2"></span>
                                        Select Hoarding
                                    </li>
                                    <li className="flex items-center">
                                        <span className="bg-[#007BFF] w-2 h-2 rounded-full mr-2"></span>
                                        Confirm & Book Your Spot
                                    </li>
                                    <li className="flex items-center">
                                        <span className="bg-[#007BFF] w-2 h-2 rounded-full mr-2"></span>
                                        Launch Campaign
                                    </li>
                                </ul>
                            </div>

                            {/* Step 3: Campaign Managers */}
                            <div className="relative flex flex-col items-center bg-[#E6F7FF] rounded-lg p-8 mb-4 md:mb-0 mx-2 w-full md:w-[20%]"> {/* Changed width for responsiveness */}
                                <div className="absolute -top-12 bg-[#007BFF] rounded-full w-24 h-24 flex items-center justify-center border-4 border-white">
                                    <FaUser size={40} className="text-white" /> {/* Person Icon */}
                                </div>
                                <h4 className="mt-16 text-xl font-semibold text-[#1C64F2]">
                                    Campaign Managers
                                </h4>
                                <ul className="text-gray-600 mt-4 text-sm space-y-2 text-left">
                                    <li className="flex items-center">
                                        <span className="bg-[#007BFF] w-2 h-2 rounded-full mr-2"></span>
                                        Set Up Your Profile
                                    </li>
                                    <li className="flex items-center">
                                        <span className="bg-[#007BFF] w-2 h-2 rounded-full mr-2"></span>
                                        Explore Hoardings
                                    </li>
                                    <li className="flex items-center">
                                        <span className="bg-[#007BFF] w-2 h-2 rounded-full mr-2"></span>
                                        Evaluate Pricing and Benefits
                                    </li>
                                    <li className="flex items-center">
                                        <span className="bg-[#007BFF] w-2 h-2 rounded-full mr-2"></span>
                                        Contact Owners
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>









                <div className="relative py-8 md:py-16 w-11/12 lg:w-10/12 xl:w-1200 m-auto">
                    <div className="space-y-16">
                        {/* First Section */}
                        <div className="flex flex-col md:flex-row md:space-x-16 md:justify-between md:items-center">
                            <div className="w-full md:w-1/2 space-y-4">
                                <h3 className="font-bold text-4xl">
                                    <span className=' text-blue-500'>Why </span>
                                    <span className='text-[#2a2968]'>Pak Hoardings</span>
                                </h3>
                                <p className='text-gray-600 text-justify'>
                                    Pak Hoardings is a platform that connects brands with hoarding owners. Our intuitive interface lets
                                    advertisers easily browse and secure prime advertising spaces by location and availability. With real-time updates and interactive maps,
                                    brands can quickly find and book ideal locations, ensuring optimal visibility and engagement for effective advertising.
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 space-y-4 mt-10">
                                <Slider {...settings} data-aos="zoom-in">
                                    {/* <div>
                                        <div className="relative w-full h-32 md:h-48 lg:h-64 rounded-lg overflow-hidden font-abc scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700">
                                            <img
                                                src={BannerImg}
                                                alt='Add Slider'
                                                autoPlay
                                                loop
                                                muted
                                                className="absolute inset-0 w-full h-full object-cover z-0"
                                            />
                                        </div>
                                    </div> */}
                                    <div>
                                        <div className="relative w-full h-32 md:h-48 lg:h-64 rounded-lg overflow-hidden font-abc scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700">
                                            <img
                                                alt='Add Slider'
                                                src={BannerImg1}
                                                autoPlay
                                                loop
                                                muted
                                                className="absolute inset-0 w-full h-full object-cover z-0"
                                            />
                                        </div>
                                    </div>
                                    {/* <div>
                                        <div className="relative w-full h-32 md:h-48 lg:h-64 rounded-lg overflow-hidden font-abc scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700">
                                            <img
                                                src={BannerImg2}
                                                alt='Add Slider'
                                                autoPlay
                                                loop
                                                muted
                                                className="absolute inset-0 w-full h-full object-cover z-0"
                                            />
                                        </div>
                                    </div> */}
                                </Slider>
                            </div>
                        </div>

                        {/* Second Section */}
                        <div className="flex flex-col md:flex-row md:space-x-16 md:justify-between md:items-center pt-10 pb-20">
                            <div className="w-full md:w-1/2 space-y-4">
                                <Slider {...settings} data-aos="zoom-in">
                                    {/* <div>
                                        <div className="relative w-full h-32 md:h-48 lg:h-64 rounded-lg overflow-hidden font-abc scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700">
                                            <img
                                                src={BannerImg}
                                                alt='Add Slider'
                                                autoPlay
                                                loop
                                                muted
                                                className="absolute inset-0 w-full h-full object-cover z-0"
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div>
                                        <div className="relative w-full h-32 md:h-48 lg:h-64 rounded-lg overflow-hidden font-abc scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700">
                                            <img
                                                alt='Add Slider'
                                                src={BannerImg1}
                                                autoPlay
                                                loop
                                                muted
                                                className="absolute inset-0 w-full h-full object-cover z-0"
                                            />
                                        </div>
                                    </div> */}
                                    <div>
                                        <div className="relative w-full h-32 md:h-48 lg:h-64 rounded-lg overflow-hidden font-abc scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700">
                                            <img
                                                src={BannerImg2}
                                                alt='Add Slider'
                                                autoPlay
                                                loop
                                                muted
                                                className="absolute inset-0 w-full h-full object-cover z-0"
                                            />
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                            <div className="w-full md:w-1/2 space-y-4">
                                <h3 className="font-bold text-4xl">
                                    <span className=' text-blue-500'>Connecting </span>
                                    <span className='text-[#2a2968]'> Brands with Opportunities</span>
                                </h3>
                                <p className='text-gray-600 text-justify'>
                                    Pak Hoardings bridges brands and premium advertising opportunities. Our platform provides a
                                    comprehensive directory of hoarding locations, allowing brands to find and secure ideal
                                    spaces that resonate with their audience. By simplifying connections with hoarding owners, we enhance visibility
                                    and create lasting impact. Join us to unlock tailored advertising solutions that elevate your brand's presence.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>



            </div>
        </div>
    );
}

export default About;
