import React from 'react'

const Privacy = () => {
  return (
    <div>
      <div className="mt-28 ">
        <div className="container mx-auto px-4 py-8 dark:text-gray-400 ">
          <h1 className="text-4xl font-extrabold tracking-tight text-slate-900 sm:text-5xl text-center">
            Privacy Policy
          </h1>
          <p className="mt-4 text-center text-base leading-7 text-slate-600">
            Last updated on October 26, 2024
          </p>
          <br />
          <div className='max-w-2xl text-justify mx-auto mt-[70px] text-gray-700'>


            <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">Safety & Compliance</h2>
            <p className="mb-4">
              We ensure that all hoarding installations meet safety and regulatory standards for both the hoarding owner and advertiser.
            </p>

            <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">Quality Assurance</h2>
            <p className="mb-4">
              We work with reliable contractors to ensure high-quality, durable hoardings that maximize visibility and brand impact.
            </p>
            <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">Environmental Responsibility</h2>
            <p className="mb-4">
              Sustainable practices are encouraged in materials and installation processes, minimizing environmental impact.
            </p>
            <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">Clear Contractual Terms            </h2>
            <p className="mb-4">

              Our agreements outline clear terms for both hoarding owners and advertisers, ensuring transparency in rights, responsibilities, and fees.
            </p>
            <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">Privacy & Confidentiality</h2>
            <p className="mb-4">
              All advertiser and owner information remains confidential, used solely to facilitate agreements and installations.
            </p>
            <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">Billing & Payment Transparency</h2>
            <p className="mb-4">
              We provide detailed billing and payment terms, ensuring both parties understand the costs involved without hidden fees.
            </p>
            <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">Customer Support</h2>
            <p className="mb-4">
              We offer responsive support to resolve any issues between hoarding owners and advertisers, aiming for seamless collaboration.
            </p>


            <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">Contact Us</h2>
            <p className="mb-20">
              If you have any questions or suggestions regarding our Privacy Policy,
              feel free to contact us at 
              <a href="mailto:Pakhoardings2024@gmail.com" className="text-blue-600 hover:underline">
                pakhoardings2024@gmail.com
              </a>.
            </p>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Privacy
