import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from "../../images/Sample1.png";
import BannerImg from "../../images/Stable.png";
import BannerImg1 from "../../images/Vision.png";
import BannerImg2 from "../../images/Electronic.png";
import { Link } from "react-router-dom";

const Services = ({ setProgress }) => {
  useEffect(() => {
    setProgress(40);
    setTimeout(() => setProgress(80), 1000);
    setTimeout(() => setProgress(100), 2000);
  }, [setProgress]);

  return (
    <div>
      <div className="relative w-full h-1/4 font-abc scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700">
        <div className="absolute inset-0 bg-black opacity-60 z-10"></div>
        <img
          src={Banner}
          alt="Add Slider"
          className="absolute inset-0 w-full h-full object-cover z-0"
        />
        <section className="relative z-20 flex items-center justify-center w-full h-full">
          <div className="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-36">
            <p className="mt-2 font-bold text-4xl text-white lg:text-md sm:px-16 lg:px-48">
              Our Services
            </p>
            <div className="mx-auto mt-1 bg-blue-500 h-1 w-36"></div>
            <p className="mt-2 font-normal text-white lg:text-md sm:px-16 lg:px-48">
              <nav
                className="flex mx-auto max-w-screen-xl px-4 font-abc mt-3 ml-4"
                aria-label="Breadcrumb"
              >
                <ol className="inline-flex items-center space-x-1 md:space-x-2">
                  <li className="inline-flex items-center">
                    <Link
                      to="/"
                      className="inline-flex items-center text-sm font-medium text-white hover:text-blue-400"
                    >
                      <svg
                        className="w-3 h-3 mr-2.5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 1-1h2a1 1 0 1-1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                      </svg>
                      Home
                    </Link>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <svg
                        className="w-3 h-3 text-gray-400 mx-1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                      <Link
                   
                        className="text-sm font-medium text-white hover:text-blue-400"
                      >
                        Items
                      </Link>
                    </div>
                  </li>
                </ol>
              </nav>
            </p>
          </div>
        </section>
      </div>


      <div className="relative py-8 md:py-16 flex flex-col md:flex-row-reverse items-center w-11/12 lg:w-10/12 xl:w-1200 m-auto">
        <div className="md:w-1/2 p-4 text-left md:ml-8">
          <h3 className="font-bold text-4xl text-blue-500">
            Stable <span className="text-[#2a2968]">Hoarding</span>
          </h3>
          <p className="text-gray-600 mt-4 text-justify">
            At Pak Hoardings, we specialize in delivering comprehensive services
            for static hoardings that maximize visibility and impact for your
            brand. Our platform connects advertisers with prime hoarding
            locations, ensuring your message reaches the right audience at the
            right time.
          </p>
        </div>
        <div className="md:w-1/2">
          <img
            src={BannerImg}
            alt="Stable Hoarding"
            className="w-[95%] h-full object-contain"
          />
        </div>
      </div>


      <div className="relative py-8 md:py-16 flex flex-col md:flex-row items-center w-11/12 lg:w-10/12 xl:w-1200 m-auto">
        <div className="md:w-1/2 p-4 text-left md:mr-8">
          {" "}
      
          <h3 className="font-bold text-4xl text-blue-500">
            Visionary <span className="text-[#2a2968]">Hoarding</span>
          </h3>
          <p className="text-gray-600 mt-4 text-justify">
            Our approach to delivering unique value propositions and executing
            innovative artworks has redefined the standards of outdoor
            advertising. Recently, Pak Hoarding Services showcased its creative
            expertise through successful campaigns with impressive visual
            executions. We provide artistic solutions across a range of mediums,
            including billboards, roundabouts, and 3D advertising, among others.
          </p>
        </div>
        <div className="md:w-1/2">
          <img
            src={BannerImg1}
            alt="Visionary Hoarding"
            className="w-[95%] h-full object-contain"
          />
        </div>
      </div>


      <div className="relative py-8 md:py-16 flex flex-col md:flex-row-reverse items-center w-11/12 lg:w-10/12 xl:w-1200 m-auto">
        <div className="md:w-1/2 p-4 text-left md:ml-8">
          {" "}
      
          <h3 className="font-bold text-4xl text-blue-500">
            Electronic <span className="text-[#2a2968]">Hoarding</span>
          </h3>
          <p className="text-gray-600 mt-4 text-justify">
            Pak Hoarding Services specializes in managing a diverse range of
            digital media platforms, including SMDs and streamers. Our extensive
            portfolio features numerous high-visibility digital advertising
            spaces where we have successfully executed thousands of campaigns.
          </p>
        </div>
        <div className="md:w-1/2">
          <img
            src={BannerImg2}
            alt="Electronic Hoarding"
            className="w-[95%] h-full object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default Services;