import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Img from '../../images/post-add.png';
import Banner from '../../images/Sample1.png'; // Ensure the path is correct

const AddhtmlForm = ({ setProgress }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]); // New state to hold file objects
    const [form, setForm] = useState({
        name: '',
        price: '',
        description: '',
        categoryId: '',
        mobileNo: '',
        size: '',
        availabiltyDate: '',
        trafficComingFrom: '', // Ensure this is included
        trafficGoingTowards: '',
        quantity: '',

    });

    const [viewMode, setViewMode] = useState(true); // Initialize viewMode as true for view-only mode
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showDangerAlert, setShowDangerAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const fileInputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [error, setError] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);

    // const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    // const handleButtonClick = () => {
    //     setIsButtonDisabled(true);
    //     setTimeout(() => {
    //         setIsButtonDisabled(false);
    //     }, 5000);
    // };

    // For Loader
    useEffect(() => {
        setProgress(40);

        setTimeout(() => {
            setProgress(80);
        }, 1000);
        setTimeout(() => {
            setProgress(100);
        }, 2000);

    }, [])

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length > 5) {
            setError('You can only upload a maximum of 5 files.');
        } else {
            setError('');
            setFiles(selectedFiles);
        }
    };

    const getToken = () => {
        return Cookies.get('token');
    };

    useEffect(() => {
        console.log('Categories:', categories);
        console.log('Form Category ID:', form.categoryId);
    }, [categories, form.categoryId]);


    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const token = getToken();
                //https://www.techcogads.com/api/Products/GetAllProducts
                const response = await axios.get('https://www.techcogads.com/api/Products/GetAllProducts', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://www.techcogads.com/api/Categories/GetAllCategories');

                setCategories(response.data);
                console.log("Category-Data", response.data)
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchProducts();
        fetchCategories();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    const handleAddProduct = async () => {
        const token = getToken();
        const formData = new FormData();
        formData.append('name', form.name);
        formData.append('price', form.price);
        formData.append('quantity', form.quantity);
        formData.append('categoryId', form.categoryId);
        formData.append('description', form.description);
        formData.append('mobileNo', form.mobileNo);
        formData.append('priceOptions', form.priceOptions);
        formData.append('size', form.size);
        formData.append('trafficComingFrom', form.trafficComingFrom);
        formData.append('trafficGoingTowards', form.trafficGoingTowards);
        formData.append('availabiltyDate', form.availabiltyDate);

        if (fileInputRef.current.files.length > 0) {
            for (let i = 0; i < fileInputRef.current.files.length; i++) {
                formData.append('images', fileInputRef.current.files[i]);
            }
        }

        try {
            await axios.post('https://www.techcogads.com/api/Products/PostProduct', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setShowSuccessAlert(true);
            setAlertMessage('Product added successfully.');
            setTimeout(() => {
                setShowSuccessAlert(false);
                setAlertMessage('');
            }, 3000);
            window.location.reload();
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setAlertMessage(error.response.data.message);
            }
            // if (error.response && error.response.status === 400) {
            //     setAlertMessage('Monthly post limit reached. Upgrade your plan in the Pricing section.');
            // }
            else {
                setAlertMessage('Failed to add product. Please try again.');
            }
            setShowDangerAlert(true);
            setTimeout(() => {
                setShowDangerAlert(false);
                setAlertMessage('');
            }, 3000);
        } finally {
            toggleModal();
        }
    };

    const handleUpdateProduct = async () => {
        const token = getToken();

        if (!Number.isInteger(Number(form.categoryId))) {
            console.error('Invalid categoryId:', form.categoryId);
            return;
        }

        const formData = new FormData();
        formData.append('productId', selectedProduct.productId);
        formData.append('name', form.name);
        formData.append('price', form.price);
        formData.append('quantity', form.quantity);
        formData.append('categoryId', form.categoryId);
        formData.append('description', form.description);
        formData.append('mobileNo', form.mobileNo);
        formData.append('size', form.size);
        formData.append('availabiltyDate', form.availabiltyDate);

        console.log('formDataaaaa', formData)
        if (fileInputRef.current.files.length > 0) {
            formData.append('image', fileInputRef.current.files[0]);
        }

        try {
            await axios.put(`https://www.techcogads.com/api/Products/UpdateProductById/${selectedProduct.productId}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setShowSuccessAlert(true);
            setAlertMessage('Product updated successfully.');
            setTimeout(() => {
                setShowSuccessAlert(false);
                setAlertMessage('');
            }, 3000);
            window.location.reload();
            toggleModal();
        } catch (error) {
            setShowDangerAlert(true);
            setAlertMessage('Failed to update product. Please try again.');
            setTimeout(() => {
                setShowDangerAlert(false);
                setAlertMessage('');
            }, 3000);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        const token = getToken();

        const updatedProduct = {
            productId: selectedProduct.productId,
            name: form.name,
            price: form.price,
            quantity: form.quantity,
            categoryId: form.categoryId,
            description: form.description,
            mobileNo: form.mobileNo,
            size: form.size,
            availabiltyDate: form.availabiltyDate,
        };
        console.log("updatedProduct", updatedProduct)

        const formData = new FormData();
        formData.append('productId', selectedProduct.productId);
        formData.append('name', updatedProduct.name);
        formData.append('price', updatedProduct.price);
        formData.append('quantity', updatedProduct.quantity);
        formData.append('categoryId', updatedProduct.categoryId);
        formData.append('description', updatedProduct.description);
        formData.append('mobileNo', updatedProduct.mobileNo);
        formData.append('priceOptions', updatedProduct.priceOptions);
        formData.append('size', updatedProduct.size);
        formData.append('availabiltyDate', form.availabiltyDate);

        if (fileInputRef.current.files.length > 0) {
            formData.append('image', fileInputRef.current.files[0]);
        }

        try {
            await axios.put(`https://www.techcogads.com/api/Products/UpdateProductById/${selectedProduct.productId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setShowSuccessAlert(true);
            setAlertMessage('Product updated successfully.');
            setTimeout(() => {
                setShowSuccessAlert(false);
                setAlertMessage('');
            }, 3000);
            window.location.reload();
            toggleModal();
        } catch (error) {
            setShowDangerAlert(true);
            setAlertMessage('Failed to update product. Please try again.');
            setTimeout(() => {
                setShowDangerAlert(false);
                setAlertMessage('');
            }, 3000);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedProduct) {
            await handleUpdateProduct();
        } else {
            await handleAddProduct();
        }
    };


    const handleDelete = async (productId) => {
        try {
            const token = getToken();
            await axios.post(`https://www.techcogads.com/api/Products/DeleteProduct/${productId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setShowDeleteModal(false);
            setShowSuccessAlert(true);
            setAlertMessage('Product deleted successfully.');
            setTimeout(() => {
                setShowSuccessAlert(false);
                setAlertMessage('');
            }, 3000);
            window.location.reload();
        } catch (error) {
            setShowDangerAlert(true);
            setAlertMessage('Failed to delete product. Please try again.');
        }
    };

    const openEditModal = (product) => {
        setSelectedProduct(product);
        setForm({
            ...form,
            name: product.name,
            priceOptions: product.priceOptions,
            price: product.price,
            quantity: product.quantity,
            mobileNo: product.mobileNo,
            categoryId: product.categoryId || '', // Ensure categoryId is set correctly
            size: product.size,
            trafficComingFrom: product.trafficComingFrom,
            trafficGoingTowards: product.trafficGoingTowards,
            description: product.description,
            availabiltyDate: product.availabiltyDate ? product.availabiltyDate.split('T')[0] : '',
            image: null,
        });
        setViewMode(false);
        setIsOpen(true);
    };

    const openDetailsModal = (product) => {
        setSelectedProduct(product);
        setForm({
            name: product.name || '',
            price: product.price ? product.price.toString() : '',
            quantity: product.quantity ? product.quantity.toString() : '',
            categoryId: product.categoryId ? product.categoryId.toString() : '',
            mobileNo: product.mobileNo ? product.mobileNo.toString() : '',
            description: product.description || '',
            size: product.size || '',
            trafficComingFrom: product.trafficComingFrom || '',
            trafficGoingTowards: product.trafficGoingTowards || '',
            availabiltyDate: product.availabiltyDate || '',
            priceOptions: product.priceOptions || '',
        });
        setViewMode(true); // Set view mode to true to disable inputs
        setIsOpen(true);
    };

    const openDeleteModal = (product) => {
        setSelectedProduct(product);
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedProduct(null);
    };

    const toggleModal = () => {
        setIsOpen(!isOpen);
        setSelectedProduct(null);
        setForm({
            name: '',
            price: '',
            description: '',
            categoryId: '',
            mobileNo: ''
        });
        setViewMode(false); // Reset view mode when closing modal
    };

    const handleRoleSelection = (Price) => {
        setForm(prevFormData => ({
            ...prevFormData,
            priceOptions: Price
        }));
        setDropdownOpen(false);
    };


    return (
        <>
            <div>
                <div className="relative w-full h-1/4 md:h-1/4 lg:h-1/4 xl:h-1/4 font-abc scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700">
                    <div className="absolute inset-0 bg-black opacity-60 z-10"></div>
                    <img
                        src={Banner}
                        alt='Add Slider'
                        className="absolute inset-0 w-full h-full object-cover z-0"
                    />
                    <section className="relative z-20 flex items-center justify-center w-full h-full">
                        <div className="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-36">
                            <p className="mt-2 font-bold text-4xl text-white lg:text-md sm:px-16 lg:px-48">
                                Post Your Add
                            </p>
                            <div className="mx-auto mt-1 bg-blue-500 h-1 w-44"></div>
                            <p className="mt-2 font-normal text-white lg:text-md sm:px-16 lg:px-48">
                                <nav className="flex mx-auto max-w-screen-xl px-4 font-abc mt-3 ml-4" aria-label="Breadcrumb">
                                    <ol className="inline-flex items-center justify-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                                        <li className="inline-flex items-center">
                                            <Link to="/" className="inline-flex items-center text-sm font-medium text-white hover:text-blue-400">
                                                <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                                </svg>
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="flex items-center">
                                                <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                                </svg>
                                                <Link to="/add-post" className="ms-1 text-sm font-medium text-white hover:text-blue-400 md:ms-2">Post Ad</Link>
                                            </div>
                                        </li>
                                    </ol>
                                </nav>
                            </p>
                        </div>
                    </section>
                </div>
            </div>

            {/* Alerts */}
            {showSuccessAlert && (
                <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white text-center py-2 z-50">
                    {alertMessage}
                </div>
            )}

            {showDangerAlert && (
                <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-red-400 via-red-500 to-red-600 text-white text-center py-2 z-50">
                    {alertMessage}
                </div>
            )}

            <section className='font-abc bg-[#FFFAFA] p-4'>
                <div className="container mx-auto max-w-screen-xl flex flex-col md:flex-row">
                    <div className="mt-24 hidden md:block md:w-1/2 lg:flex justify-center md:justify-start bg-custom-gradient"
                        style={{ borderRadius: '35% 65% 100% 0% / 70% 40% 60% 30%  ' }}>
                        <h1 className="mt-24 text-3xl text-center text-white font-extrabold underline rounded-r-lg">
                            Post Your Ad
                            <img className='mt-20' src={Img} alt="logo" />
                        </h1>
                    </div>

                    <h1 className="block md:hidden mt-24 text-2xl text-center text-black font-extrabold underline rounded-r-lg">
                        Post Your Ad
                    </h1>
                    <div className="md:w-1/3 container mx-auto items-center">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4 mt-16">
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                    Location
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder='e.g Block J2, Wapda Town, Lahore'
                                    value={form.name}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    required
                                // disabled={viewMode} // Disable input in view mode
                                />
                            </div>

                            <div className="relative mb-3">
                                <label htmlFor="size" className="block text-sm font-medium text-gray-700">
                                    Payment Plan
                                </label>
                                <button
                                    type="button"
                                    id="priceOptions"
                                    name="priceOptions"
                                    value={form.priceOptions}
                                    onChange={handleInputChange}
                                    onClick={() => setDropdownOpen(!dropdownOpen)}
                                    aria-haspopup="listbox"
                                    aria-expanded="true"
                                    className="w-full p-2.5 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 flex items-center justify-between"
                                >
                                    {form.priceOptions || 'Select Pricing Options'}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-gray-500"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                                {dropdownOpen && (
                                    <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10">
                                        <ul
                                            tabIndex="-1"
                                            role="listbox"
                                            aria-labelledby="listbox-label"
                                            aria-activedescendant="listbox-option-3"
                                            className="py-1 overflow-auto text-base ring-1 ring-black ring-opacity-5 max-h-60 rounded-md shadow-xs focus:outline-none sm:text-sm"
                                        >
                                            <li
                                                onClick={() => handleRoleSelection('Monthly')}
                                                className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                            >
                                                <div className="flex items-center">
                                                    <span className="ml-3 block font-normal truncate">Monthly</span>
                                                </div>
                                            </li>
                                            <li
                                                onClick={() => handleRoleSelection('Quartly')}
                                                className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                            >
                                                <div className="flex items-center">
                                                    <span className="ml-3 block font-normal truncate">Quartly</span>
                                                </div>
                                            </li>
                                            <li
                                                onClick={() => handleRoleSelection('Annualy')}
                                                className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                            >
                                                <div className="flex items-center">
                                                    <span className="ml-3 block font-normal truncate">Annualy</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className="mb-3">
                                <div className="mb-3">
                                    <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                                        Price (PKR)
                                    </label>
                                    <input
                                        type="number"
                                        id="price"
                                        name="price"
                                        value={form.price}
                                        placeholder='Ad price'
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        required
                                        min="0"
                                        onInput={(e) => {
                                            if (e.target.value < 0) e.target.value = 0;
                                        }}
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="mobileNo" className="block text-sm font-medium text-gray-700">
                                        Mobile No
                                    </label>
                                    <input
                                        type="tel"
                                        id="mobileNo"
                                        name="mobileNo"
                                        placeholder="e.g 03110405203"
                                        value={form.mobileNo}
                                        onChange={handleInputChange}
                                        pattern="03\d{9}"
                                        maxLength="11"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="categoryId" className="block text-sm font-medium text-gray-700">
                                        Category
                                    </label>
                                    <select
                                        id="categoryId"
                                        name="categoryId"
                                        value={form.categoryId}
                                        onChange={handleInputChange}
                                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        required
                                    >
                                        <option value="">Select billboard category</option>
                                        {categories.map((category) => (
                                            <option key={category.categoryId} value={category.categoryId}>                                           
                                                {category.name}
                                            </option>
                                        ))}
                                        
                                    </select>
                                </div>

                                {/* Quantity input field */}
                                {/* <div className="mb-3">
                                    <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">
                                        Quantity
                                    </label>
                                    <input
                                        type="number"
                                        id="quantity"
                                        name="quantity"
                                        value={form.quantity}
                                        onChange={handleInputChange}
                                        placeholder="Available only for Digital Streamers"
                                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        required
                                        disabled={form.categoryId !== "2046"} // Replace with the categoryId or condition you need
                                    />
                                </div> */}

                                {/* <div className="mb-3">
                                    <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                                        Quantity
                                    </label>
                                    <input
                                        type="number"
                                        id="quantity"
                                        name="quantity"
                                        value={form.quantity}
                                        placeholder='Steamer Quantity'
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        required
                                        min="0"
                                        onInput={(e) => {
                                            if (e.target.value < 0) e.target.value = 0;
                                        }}
                                    />
                                </div> */}
                                <div className="mb-3">
                                    <label htmlFor="size" className="block text-sm font-medium text-gray-700">
                                        Size (WxH)
                                    </label>
                                    <input
                                        type="text"
                                        id="size"
                                        name="size"
                                        value={form.size}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        placeholder="e.g 20x20 "
                                        required
                                        pattern="^\d+x\d+$"
                                        onInput={(e) => {
                                            const value = e.target.value;
                                            // Only allow digits and a single 'x' in the format "number x number"
                                            if (!/^\d*x?\d*$/.test(value)) {
                                                e.target.value = value.slice(0, -1);
                                            }
                                        }}
                                    />
                                </div>


                            </div>
                            <div className="mb-3">
                                <label htmlFor="trafficComingFrom" className="block text-sm font-medium text-gray-700">
                                    Traffic Coming From
                                </label>
                                <input
                                    type="text"
                                    id="trafficComingFrom"
                                    name="trafficComingFrom"
                                    value={form.trafficComingFrom}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder='e.g Jinnah hospital to Karim Market'
                                    maxLength={100} // Set the maximum length here
                                    required
                                />

                            </div>

                            <div className="mb-3">
                                <label htmlFor="trafficGoingTowards" className="block text-sm font-medium text-gray-700">
                                    Trafic Going Towards
                                </label>
                                <input
                                    type="text"
                                    id="trafficGoingTowards"
                                    name="trafficGoingTowards"
                                    value={form.trafficGoingTowards}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder='e.g Karim Market to Jinnah Hospital'
                                    maxLength={100} // Set the maximum length here
                                    required
                                // disabled={viewMode} // Disable input in view mode
                                />

                            </div>

                            <div className="mb-3">
                                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                                    Description
                                </label>
                                <textarea
                                    id="description"
                                    name="description"
                                    value={form.description}
                                    placeholder='e.g Describe your ad here, including key details like location, size, and any special features.'
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    required
                                // disabled={viewMode} // Disable input in view mode
                                />
                            </div>
                            <div className="mb-3">
                                <label for="date" className="block text-sm font-medium text-gray-700">
                                    Available date
                                </label>
                                <input
                                    type="date"
                                    id="availabiltyDate"
                                    name="availabiltyDate"
                                    value={form.availabiltyDate}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                            <div className="mb-5">
                                <label htmlFor="image" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Select 4 Images together. (only .jpg & .png)
                                </label>
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    id="image"
                                    name="image"
                                    accept=".jpg,.jpeg,.png"
                                    multiple
                                    className="mt-2 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:text-white dark:border-gray-600"
                                />
                            </div>
                            <div className="flex justify-end">
                                <button
                                    // onClick={handleButtonClick}
                                    // disabled={isButtonDisabled}
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                                >
                                    Post Add
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AddhtmlForm;

