import React from 'react';
import Img1 from '../../images/Monial1.jpg';
import Img2 from '../../images/Monial2.jpg';
import Img3 from '../../images/Monial3.jpg';
import Img4 from '../../images/Monial4.jpg';
import Slider from "react-slick";
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// AOS.init({
//     offset: 100,
//     duration: 2000,
// });
const MySwiperComponent = () => {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="font-arapey slider-container mb-8 w-full overflow-hidden scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700">
            <span className="text-center text-lg  text-[#1C64F2] font-medium mb-2 block mt-6">Testimonial</span>
      
            <h2 className="text-center text-4xl font-bold text-gray-900 leading-[3.25rem] mb-8">Thousands of Customers gave their <br />
                <span
                    className=" text-transparent bg-clip-text bg-gradient-to-tr bg-blue-500">
                    Feedback
                </span>
            </h2>
            <Slider {...settings}>
                <div>
                    <h3>
                        <div className="mr-3 ml-3">
                            <div
                                className="group bg-white border border-solid border-gray-300 rounded-xl p-6 transition-all duration-500  w-full mx-auto hover:border-blue-600 hover:shadow-sm slide_active:border-indigo-600">
                                <div className="">
                                    <div className="flex items-center mb-7 gap-2 text-amber-500 transition-all duration-500  ">
                                        <svg className="w-5 h-5" viewBox="0 0 18 17" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                                                fill="currentColor" />
                                        </svg>
                                        <span className="text-base font-semibold text-blue-500">4.9</span>
                                    </div>
                                    <p
                                        className="text-base text-gray-600 leading-6  transition-all duration-500 pb-8 group-hover:text-gray-800 slide_active:text-gray-800">
                                        "Pak Hoardings has revolutionized how I connect with advertisers. The platform is user-friendly and has made finding the right opportunities much easier.
                                    </p>
                                </div>
                                <div className="flex items-center gap-5 border-t border-solid border-gray-200 pt-5">
                                    <img className="rounded-full h-10 w-10" src={Img1}
                                        alt="avatar" />
                                    <div className="block">
                                        <h5 className="text-gray-900 font-medium transition-all duration-500  mb-1">Alex Johnson</h5>
                                        <span className="text-sm leading-4 text-gray-500">Chief Marketing Officer</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </h3>
                </div>
                <div>
                    <h3>
                        <div className="mr-3 ml-3">
                            <div class="swiper-slide">
                                <div
                                    class="group bg-white border border-solid border-gray-300 flex justify-between flex-col rounded-xl p-6 transition-all duration-500  w-full mx-auto hover:border-blue-600 slide_active:border-indigo-600 hover:shadow-sm">
                                    <div class="">
                                        <div class="flex items-center mb-7 gap-2 text-amber-500 transition-all duration-500  ">
                                            <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                                                    fill="currentColor" />
                                            </svg>
                                            <span class="text-base font-semibold text-blue-500">4.9</span>
                                        </div>
                                        <p
                                            class="text-base text-gray-600 leading-6  transition-all duration-500 pb-8 group-hover:text-gray-800 slide_active:text-gray-800">
                                            Thanks to Pak Hoardings, I’ve been able to streamline my ad placements and reach the right audience. It’s a game-changer for my advertising strategy.
                                        </p>
                                    </div>
                                    <div class="flex items-center gap-5 pt-5 border-t border-solid border-gray-200">
                                        <img className="rounded-full h-10 w-10" src={Img2} />
                                        <div class="block">
                                            <h5 class="text-gray-900 font-medium transition-all duration-500  mb-1">
                                                Jordan Smith
                                            </h5>
                                            <span class="text-sm leading-4 text-gray-500">
                                                Advertising Director
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </h3>
                </div>
                <div>
                    <h3>
                        <div className="mr-3 ml-3">
                            <div
                                className="group bg-white border border-solid border-gray-300 rounded-xl p-6 transition-all duration-500  w-full mx-auto hover:border-blue-600 hover:shadow-sm slide_active:border-indigo-600">
                                <div className="">
                                    <div className="flex items-center mb-7 gap-2 text-amber-500 transition-all duration-500  ">
                                        <svg className="w-5 h-5" viewBox="0 0 18 17" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                                                fill="currentColor" />
                                        </svg>
                                        <span className="text-base font-semibold text-blue-500">4.9</span>
                                    </div>
                                    <p
                                        className="text-base text-gray-600 leading-6  transition-all duration-500 pb-8 group-hover:text-gray-800 slide_active:text-gray-800">
                                        Pak Hoardings offers a seamless way to manage ad placements. The platform’s efficiency has greatly improved my approach to finding the best options.
                                    </p>
                                </div>
                                <div className="flex items-center gap-5 border-t border-solid border-gray-200 pt-5">
                                    <img className="rounded-full h-10 w-10" src={Img3} />
                                    <div className="block">
                                        <h5 className="text-gray-900 font-medium transition-all duration-500  mb-1">Samantha Lee</h5>
                                        <span className="text-sm leading-4 text-gray-500">
                                            Business Development Manager
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </h3>
                </div>
                <div>
                    <h3>
                        <div className="mr-3 ml-3">
                            <div class="swiper-slide">
                                <div
                                    class="group bg-white border border-solid border-gray-300 flex justify-between flex-col rounded-xl p-6 transition-all duration-500  w-full mx-auto hover:border-blue-600 slide_active:border-indigo-600 hover:shadow-sm">
                                    <div class="">
                                        <div class="flex items-center mb-7 gap-2 text-amber-500 transition-all duration-500  ">
                                            <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                                                    fill="currentColor" />
                                            </svg>
                                            <span class="text-base font-semibold text-blue-500">4.9</span>
                                        </div>
                                        <p
                                            class="text-base text-gray-600 leading-6  transition-all duration-500 pb-8 group-hover:text-gray-800 slide_active:text-gray-800">
                                            With Pak Hoardings, connecting with the right advertisers is a breeze. The user-friendly interface has made managing my ads straightforward and efficien
                                        </p>
                                    </div>
                                    <div class="flex items-center gap-5 pt-5 border-t border-solid border-gray-200">
                                        <img className="rounded-full h-10 w-10" src={Img4} />
                                        <div class="block">
                                            <h5 class="text-gray-900 font-medium transition-all duration-500  mb-1">
                                                Taylor Brown
                                            </h5>
                                            <span class="text-sm leading-4 text-gray-500">
                                                Marketing Strategist
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </h3>
                </div>
            </Slider>
        </div>
    );
}

export default MySwiperComponent;
