import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Cookies from 'js-cookie';
import { CgDetailsMore } from "react-icons/cg";

const Postlsting = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]); // New state to hold file objects
    const [showAlert, setShowAlert] = useState(false);
    const [selectedImage, setSelectedImage] = useState(''); // State to track the selected image
    const [selectedImages, setSelectedImages] = useState([]);

    const [form, setForm] = useState({
        name: '',
        price: '',
        description: '',
        categoryId: '',
        mobileNo: '',
        size: '',
        availabiltyDate: '',
        trafficGoingTowards: '',
        trafficComingFrom: '',
    });
    const [viewMode, setViewMode] = useState(true); // Initialize viewMode as true for view-only mode
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showDangerAlert, setShowDangerAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const fileInputRef = useRef(null);

    const getToken = () => {
        return Cookies.get('token');
    };

    useEffect(() => {
        console.log('Categories:', categories);
        console.log('Form Category ID:', form.categories);
    }, [categories, form.categoryId]);


    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const token = getToken();
                //https://techcogads.com/api/Products/GetAllProducts
                const response = await axios.get('https://www.techcogads.com/api/Products/GetAllProducts', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://www.techcogads.com/api/Categories/GetAllCategories');
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchProducts();
        fetchCategories();
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    const handleAddProduct = async () => {
        const token = getToken();
        const formData = new FormData();
        formData.append('name', form.name);
        formData.append('price', form.price);
        formData.append('categoryId', form.categoryId);
        formData.append('description', form.description);
        formData.append('mobileNo', form.mobileNo);
        formData.append('priceOptions', form.priceOptions);
        formData.append('size', form.size);
        formData.append('trafficComingFrom', form.trafficComingFrom);
        formData.append('trafficGoingTowards', form.trafficGoingTowards);
        formData.append('availabiltyDate', form.availabiltyDate);

        if (fileInputRef.current.files.length > 0) {
            for (let i = 0; i < fileInputRef.current.files.length; i++) {
                formData.append('images', fileInputRef.current.files[i]);
            }
        }

        try {
            await axios.post('https://www.techcogads.com/api/Products/PostProduct', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setShowSuccessAlert(true);
            setAlertMessage('Product added successfully.');
            setTimeout(() => {
                setShowSuccessAlert(false);
                setAlertMessage('');
            }, 3000);
            window.location.reload();
        } catch (error) {
            // Check if there's a response and if it contains a message
            if (error.response && error.response.data && error.response.data.message) {
                setAlertMessage(error.response.data.message);
            }
            // if (error.response) {
            //     const errorMessage = error.response.data.message || 'Failed to add product. Please try again.';
            //     setAlertMessage(errorMessage);
            // }
            else {
                setAlertMessage('Failed to add product. Please try again.');
            }

            setShowDangerAlert(true);
            setTimeout(() => {
                setShowDangerAlert(false);
                setAlertMessage('');
            }, 3000);
        } finally {
            toggleModal();
        }
    };

    const handleUpdateProduct = async () => {
        const token = getToken();

        if (!Number.isInteger(Number(form.categoryId))) {
            console.error('Invalid categoryId:', form.categoryId);
            return;
        }

        const formData = new FormData();
        formData.append('productId', selectedProduct.productId);
        formData.append('name', form.name);
        formData.append('price', form.price);
        formData.append('categoryId', form.categoryId);
        formData.append('description', form.description);
        formData.append('mobileNo', form.mobileNo);
        formData.append('size', form.size);
        formData.append('availabiltyDate', form.availabiltyDate);
        formData.append('trafficComingFrom', form.trafficComingFrom);
        formData.append('trafficGoingTowards', form.trafficGoingTowards);

        console.log('formDataaaaa', formData)
        if (fileInputRef.current.files.length > 0) {
            formData.append('image', fileInputRef.current.files[0]);
        }

        try {
            await axios.put(`https://www.techcogads.com/api/Products/UpdateProductById`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setShowSuccessAlert(true);
            setAlertMessage('Product updated successfully.');
            setTimeout(() => {
                setShowSuccessAlert(false);
                setAlertMessage('');
            }, 3000);
            window.location.reload();
            toggleModal();
        } catch (error) {
            setShowDangerAlert(true);
            setAlertMessage('Failed to update product. Please try again.');
            setTimeout(() => {
                setShowDangerAlert(false);
                setAlertMessage('');
            }, 3000);
        }
        finally {
            toggleModal();
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        const token = getToken();

        const updatedProduct = {
            productId: selectedProduct.productId,
            name: form.name,
            price: form.price,
            categoryId: form.categoryId,
            description: form.description,
            mobileNo: form.mobileNo,
            size: form.size,
            availabiltyDate: form.availabiltyDate,
            trafficComingFrom: form.trafficComingFrom,
            trafficGoingTowards: form.trafficGoingTowards
        };
        console.log("updatedProduct", updatedProduct)

        const formData = new FormData();
        formData.append('productId', selectedProduct.productId);
        formData.append('name', updatedProduct.name);
        formData.append('price', updatedProduct.price);
        formData.append('categoryId', updatedProduct.categoryId);
        formData.append('description', updatedProduct.description);
        formData.append('mobileNo', updatedProduct.mobileNo);
        formData.append('priceOptions', updatedProduct.priceOptions);
        formData.append('size', updatedProduct.size);
        formData.append('availabiltyDate', form.availabiltyDate);
        formData.append('trafficGoingTowards', form.trafficGoingTowards);
        formData.append('trafficComingFrom', form.trafficComingFrom);

        if (fileInputRef.current.files.length > 0) {
            formData.append('image', fileInputRef.current.files[0]);
        }

        try {
            await axios.put(`https://www.techcogads.com/api/Products/UpdateProductById/${selectedProduct.productId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setShowSuccessAlert(true);
            setAlertMessage('Product updated successfully.');
            setTimeout(() => {
                setShowSuccessAlert(false);
                setAlertMessage('');
            }, 3000);
            window.location.reload();
            toggleModal();
        } catch (error) {
            setShowDangerAlert(true);
            setAlertMessage('Failed to update product. Please try again.');
            setTimeout(() => {
                setShowDangerAlert(false);
                setAlertMessage('');
            }, 3000);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedProduct) {
            await handleUpdateProduct();
        } else {
            await handleAddProduct();
        }
    };


    const handleDelete = async (productId) => {
        try {
            const token = getToken();
            await axios.post(`https://www.techcogads.com/api/Products/DeleteProduct/${productId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setShowDeleteModal(false);
            setShowSuccessAlert(true);
            setAlertMessage('Product deleted successfully.');
            setTimeout(() => {
                setShowSuccessAlert(false);
                setAlertMessage('');
            }, 3000);
            window.location.reload();
        } catch (error) {
            setShowDangerAlert(true);
            setAlertMessage('Failed to delete product. Please try again.');
        }
    };

    const openEditModal = (product) => {
        setSelectedProduct(product);
        setForm({
            ...form,
            name: product.name,
            priceOptions: product.priceOptions,
            price: product.price,
            mobileNo: product.mobileNo,
            categoryId: product.categoryId || '', // Ensure categoryId is set correctly
            size: product.size,
            trafficComingFrom: product.trafficComingFrom,
            trafficGoingTowards: product.trafficGoingTowards,
            description: product.description,
            availabiltyDate: product.availabiltyDate ? product.availabiltyDate.split('T')[0] : '',
            image: null,
        });
        setViewMode(false);
        setIsOpen(true);
    };

    const openDetailsModal = (product) => {
        setSelectedProduct(product);
        setForm({
            name: product.name || '',
            price: product.price ? product.price.toString() : '',
            categoryId: product.categoryId ? product.categoryId.toString() : '',
            mobileNo: product.mobileNo ? product.mobileNo.toString() : '',
            description: product.description || '',
            size: product.size || '',
            trafficComingFrom: product.trafficComingFrom || '',
            trafficGoingTowards: product.trafficGoingTowards || '',
            availabiltyDate: product.availabiltyDate || '',
            priceOptions: product.priceOptions || '',
        });
        setViewMode(true); // Set view mode to true to disable inputs
        setIsOpen(true);
    };

    const openDeleteModal = (product) => {
        setSelectedProduct(product);
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedProduct(null);
    };

    const toggleModal = () => {
        setIsOpen(!isOpen);
        setSelectedProduct(null);
        setForm({
            name: '',
            price: '',
            description: '',
            categoryId: '',
            mobileNo: ''
        });
        setViewMode(false); // Reset view mode when closing modal
    };

    const handleRoleSelection = (Price) => {
        setForm(prevFormData => ({
            ...prevFormData,
            priceOptions: Price
        }));
        setDropdownOpen(false);
    };

    // Render loading indicator if products are still loading
    // if (products.length === 0) {
    //     return (
    //         <div className="flex justify-center items-center">
    //             <div role="status">
    //                 <svg aria-hidden="true" className="inline w-16 h-16 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                     <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
    //                     <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
    //                 </svg>
    //             </div>
    //         </div>
    //     );
    // }

    return (
        <div className="relative overflow-x-auto font-arapey">
            <button
                onClick={() => { setViewMode(false); toggleModal(); }}
                className="mb-2 block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button"
            >
                New Ad
            </button>

            {/* Alerts */}
            {showSuccessAlert && (
                <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white text-center py-2 z-50">
                    {alertMessage}
                </div>
            )}

            {showDangerAlert && (
                <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-red-400 via-red-500 to-red-600 text-white text-center py-2 z-50">
                    {alertMessage}
                </div>
            )}

            {/* Product Table */}
            {products.length === 0 ? (
                <div>
                    <section className="bg-white dark:bg-gray-900">
                        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                            <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">No Ad Yet!</h2>
                                <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">What are you waiting for... Post your Ad and grow your business</p>
                            </div>

                        </div>
                    </section>
                </div>
            ) : (
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">Billboard Location</th>
                            <th scope="col" className="px-6 py-3">Category</th>
                            <th scope="col" className="px-6 py-3">Size</th>
                            <th scope="col" className="px-6 py-3">Price</th>
                            <th scope="col" className="px-6 py-3">Payment Plan</th>
                            <th scope="col" className="px-6 py-3">Images</th>
                            <th scope="col" className="px-6 py-3">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product) => (
                            <tr key={product.productId} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <td className="px-6 py-4">{product.name}</td>
                                <td className="px-6 py-4">{product.categoryName}</td>
                                <td className="px-6 py-4">{product.size}</td>
                                <td className="px-6 py-4">{product.price}</td>
                                <td className="px-6 py-4">{product.priceOptions}</td>

                                {product.imagePath.length > 0 && (
                                    <td className="px-6 py-4">
                                        <img
                                            src={`https://www.techcogads.com/${product.imagePath[0]}`} // Only the first image
                                            alt={`Product 1`} // Adjust the alt text if needed
                                            className="w-20 h-20 object-cover"
                                        />
                                    </td>
                                )}
                                <td className="px-6 py-4">
                                    <button onClick={() => openEditModal(product)} className="text-blue-600 hover:text-blue-800">
                                        <FaEdit />
                                    </button>
                                    <button onClick={() => openDeleteModal(product)} className="text-red-600 hover:text-red-800 ml-2">
                                        <RiDeleteBin6Line />
                                    </button>
                                    <button onClick={() => openDetailsModal(product)} className="text-yellow-400 hover:text-yellow-600 ml-2">
                                        <CgDetailsMore />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg">
                        <p>Are you sure you want to delete this product?</p>
                        <div className="mt-4">
                            <button
                                onClick={() => handleDelete(selectedProduct.productId)}
                                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                            >
                                Delete
                            </button>
                            <button
                                onClick={closeDeleteModal}
                                className="bg-gray-300 text-black px-4 py-2 rounded"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Add/Edit Product Modal */}
            {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg mx-4 mt-96 sm:mt-24 md:mt-32 lg:mt-48 xl:mt-64">
                        <h2 className="text-lg font-semibold mb-4">{selectedProduct ? 'Edit Product' : 'Add Product'}</h2>
                        <form onSubmit={handleSubmit}>
                            <h1 className="mt-24 text-3xl text-center text-black font-extrabold underline rounded-r-lg">
                                Post Your Ad
                            </h1>
                            <div className="mb-4 mt-16">
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                    Location
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={form.name}
                                    placeholder='e.g Block J2, Wapda Town, Lahore'
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    required
                                    disabled={viewMode} // Disable input in view mode
                                />
                            </div>

                            <div className="relative mb-3">
                                <label htmlFor="size" className="block text-sm font-medium text-gray-700">
                                    Payment Plan
                                </label>
                                <button
                                    type="button"
                                    id="priceOptions"
                                    name="priceOptions"
                                    value={form.priceOptions}
                                    onChange={handleInputChange}
                                    onClick={() => setDropdownOpen(!dropdownOpen)}
                                    aria-haspopup="listbox"
                                    aria-expanded="true"
                                    className="w-full p-2.5 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 flex items-center justify-between"
                                >
                                    {form.priceOptions || 'Select Pricing Options'}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-gray-500"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                                {dropdownOpen && (
                                    <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10">
                                        <ul
                                            tabIndex="-1"
                                            role="listbox"
                                            aria-labelledby="listbox-label"
                                            aria-activedescendant="listbox-option-3"
                                            className="py-1 overflow-auto text-base ring-1 ring-black ring-opacity-5 max-h-60 rounded-md shadow-xs focus:outline-none sm:text-sm"
                                        >
                                            <li
                                                onClick={() => handleRoleSelection('Monthly')}
                                                className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                            >
                                                <div className="flex items-center">
                                                    <span className="ml-3 block font-normal truncate">Monthly</span>
                                                </div>
                                            </li>
                                            <li
                                                onClick={() => handleRoleSelection('Quartly')}
                                                className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                            >
                                                <div className="flex items-center">
                                                    <span className="ml-3 block font-normal truncate">Quartly</span>
                                                </div>
                                            </li>
                                            <li
                                                onClick={() => handleRoleSelection('Annualy')}
                                                className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                            >
                                                <div className="flex items-center">
                                                    <span className="ml-3 block font-normal truncate">Annualy</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className="mb-3">
                                <div className="mb-3">
                                    <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                                        Price (PKR)
                                    </label>
                                    <input
                                        type="number"
                                        id="price"
                                        name="price"
                                        value={form.price}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        required
                                        placeholder='Ad price'
                                        disabled={viewMode} // Disable input in view mode
                                        min="0"
                                        onInput={(e) => {
                                            if (e.target.value < 0) e.target.value = 0;
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="mobileNo" className="block text-sm font-medium text-gray-700">
                                        Mobile No
                                    </label>
                                    <input
                                        type="text"
                                        id="mobileNo"
                                        name="mobileNo"
                                        value={form.mobileNo}
                                        placeholder="e.g 03110405203"
                                        onChange={handleInputChange}
                                        pattern="03\d{9}"
                                        maxLength="11"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        required
                                        disabled={viewMode} // Disable input in view mode
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="categoryId" className="block text-sm font-medium text-gray-700">
                                        Category
                                    </label>
                                    <select
                                        id="categoryId"
                                        name="categoryId"
                                        value={form.categoryId}
                                        onChange={handleInputChange}
                                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        required
                                        disabled={viewMode} // Disable input in view mode
                                    >
                                        <option value="">Select billboard category</option>
                                        {categories.map((category) => (
                                            <option key={category.categoryId} value={category.categoryId}>{category.name}</option>
                                        ))}
                                    </select>

                                </div>
                                {/* <div className="mb-3">
                                    <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">
                                        Quantity
                                    </label>
                                    <input
                                        type="number"
                                        id="quantity"
                                        name="quantity"
                                        value={form.quantity}
                                        onChange={handleInputChange}
                                        placeholder="Available only for Digital Streamers"
                                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        required
                                        disabled={form.categoryId !== "2051"} // Replace with the categoryId or condition you need
                                    />
                                </div> */}
                                <label htmlFor="size" className="block text-sm font-medium text-gray-700">
                                    Size (WxH)
                                </label>
                                <input
                                    type="text"
                                    id="size"
                                    name="size"
                                    value={form.size}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder='e.g 20x20'
                                    required
                                    disabled={viewMode} // Disable input in view mode
                                    pattern="^\d+x\d+$"
                                    onInput={(e) => {
                                        const value = e.target.value;
                                        // Only allow digits and a single 'x' in the format "number x number"
                                        if (!/^\d*x?\d*$/.test(value)) {
                                            e.target.value = value.slice(0, -1);
                                        }
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="trafficComingFrom" className="block text-sm font-medium text-gray-700">
                                    Trafic Coming From
                                </label>
                                <input
                                    type="text"
                                    id="trafficComingFrom"
                                    name="trafficComingFrom"
                                    value={form.trafficComingFrom}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder='e.g Jinnah hospital to Karim Market'
                                    maxLength={100} // Set the maximum length here
                                    required
                                    // maxLength={100} // Set the maximum length here
                                    disabled={viewMode} // Disable input in view mode
                                />
                                {/* <div className="mt-1 text-sm text-gray-500">
                                    Characters remaining: {100 - (form.trafficComingFrom?.length || 0)}
                                </div> */}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="trafficGoingTowards" className="block text-sm font-medium text-gray-700">
                                    Trafic Going Towards
                                </label>
                                <input
                                    type="text"
                                    id="trafficGoingTowards"
                                    name="trafficGoingTowards"
                                    value={form.trafficGoingTowards}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    required
                                    placeholder='e.g Karim Market to Jinnah Hospital'
                                    maxLength={100} // Set the maximum length here
                                    disabled={viewMode} // Disable input in view mode
                                />
                                {/* <div className="mt-1 text-sm text-gray-500">
                                    Characters remaining: {100 - (form.trafficGoingTowards?.length || 0)}
                                </div> */}
                            </div>


                            <div className="mb-3">
                                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                                    Description
                                </label>
                                <textarea
                                    id="description"
                                    name="description"
                                    value={form.description}
                                    onChange={handleInputChange}
                                    placeholder='e.g Describe your ad here, including key details like location, size, and any special features.'
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    required
                                    disabled={viewMode} // Disable input in view mode
                                />
                            </div>
                            <div class="mb-3">
                                <label for="date" class="block text-sm font-medium text-gray-700">
                                    Available date
                                </label>
                                <input
                                    type="date"
                                    id="availabiltyDate"
                                    name="availabiltyDate"
                                    value={form.availabiltyDate}
                                    onChange={handleInputChange}
                                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-5">
                                    <label htmlFor="image" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Select 4 Images together. (only .jpg & .png)
                                    </label>
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        id="image"
                                        name="image"
                                        accept=".jpg,.jpeg,.png"
                                        multiple
                                        className="mt-2 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:text-white dark:border-gray-600"
                                    />
                                </div>
                            </form>

                            <div className="flex justify-end">
                                {selectedProduct ? (
                                    <button
                                        type="button"
                                        onClick={toggleModal}
                                        className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                                    >
                                        Cancel
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        onClick={toggleModal}
                                        className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                                    >
                                        Close
                                    </button>
                                )}
                                <button
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                                    disabled={viewMode} // Disable submit button in view mode
                                >
                                    {selectedProduct ? 'Update' : 'Post Ad'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Postlsting;
