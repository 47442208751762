import React from 'react';
import './Img.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import { TypeAnimation } from 'react-type-animation';
import BannerImg from '../../videos/hd.mp4'; // Ensure the path is correct
import BannerImg1 from '../../videos/hd.mp4'; // Ensure the path is correct
import BannerImg2 from '../../videos/hd.mp4'; // Ensure the path is correct

function AutoPlay() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        cssEase: 'ease-in-out',
        prevArrow: <></>,
        nextArrow: <></>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <Slider {...settings}>
                <div>
                    <div className="relative w-full h-auto md:h-screen lg:h-screen xl:h-screen">
                        <video
                            src={BannerImg}
                            alt='Add Slider'
                            autoPlay
                            loop
                            muted
                            className="absolute inset-0 w-full h-full object-cover"
                        />
                        <div className="relative z-10 flex items-center justify-start h-full p-4">
                            <div className="text-left max-w-full">
                                <h1
                                    className="text-2xl sm:text-4xl md:text-6xl lg:text-7xl xl:text-8xl font-bold leading-tight ml-0 text-start mb-10 mt-24"
                                    style={{
                                        backgroundImage: 'linear-gradient(to right, #005aa7, #fffde4)',
                                        WebkitBackgroundClip: 'text',
                                        backgroundClip: 'text',
                                        color: 'transparent',
                                    }}
                                >
                                    <span className="block sm:hidden" style={{ fontSize: '1rem', lineHeight: '3.5rem' }}>
                                        Pak Hoardings!
                                        <br />
                                        <TypeAnimation
                                            sequence={[
                                                'Your Ad Space',
                                                1000,
                                                'Space to Influence',
                                                1000,
                                                'Reach Your Audience',
                                                1000,
                                            ]}
                                            wrapper="h6"
                                            cursor={true}
                                            repeat={Infinity}
                                        />
                                    </span>
                                    <span className="hidden sm:block" style={{ fontSize: '4.875rem', lineHeight: '6.25rem' }}>
                                        Pak Hoardings!
                                        <br />
                                        <TypeAnimation
                                            sequence={[
                                                'Your Ad Space',
                                                1000,
                                                'Space to Influence',
                                                1000,
                                                'Reach Your Audience',
                                                1000,
                                            ]}
                                            wrapper="h6"
                                            cursor={true}
                                            repeat={Infinity}
                                        />
                                    </span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="relative w-full h-auto md:h-screen lg:h-screen xl:h-screen">
                        <video
                            alt='Add Slider'
                            src={BannerImg1}
                            autoPlay
                            loop
                            muted
                            className="absolute inset-0 w-full h-full object-cover"
                        />
                        <div className="relative z-10 flex items-center justify-start h-full p-4">
                            <div className="text-left max-w-full">
                                <h1
                                    className="text-2xl sm:text-4xl md:text-6xl lg:text-7xl xl:text-8xl font-bold leading-tight ml-0 text-start mb-10 mt-24"
                                    style={{
                                        backgroundImage: 'linear-gradient(to right, #005aa7, #fffde4)',
                                        WebkitBackgroundClip: 'text',
                                        backgroundClip: 'text',
                                        color: 'transparent',
                                    }}
                                >
                                    <span className="block sm:hidden" style={{ fontSize: '1rem', lineHeight: '3.5rem' }}>
                                        Pak Hoardings!
                                        <br />
                                        <TypeAnimation
                                            sequence={[
                                                'Your Ad Space',
                                                1000,
                                                'Space to Influence',
                                                1000,
                                                'Reach Your Audience',
                                                1000,
                                            ]}
                                            wrapper="h6"
                                            cursor={true}
                                            repeat={Infinity}
                                        />
                                    </span>
                                    <span className="hidden sm:block" style={{ fontSize: '4.875rem', lineHeight: '6.25rem' }}>
                                        Pak Hoardings!
                                        <br />
                                        <TypeAnimation
                                            sequence={[
                                                'Your Ad Space',
                                                1000,
                                                'Space to Influence',
                                                1000,
                                                'Reach Your Audience',
                                                1000,
                                            ]}
                                            wrapper="h6"
                                            cursor={true}
                                            repeat={Infinity}
                                        />
                                    </span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="relative w-full h-auto md:h-screen lg:h-screen xl:h-screen">
                        <video
                            src={BannerImg2}
                            alt='Add Slider'
                            autoPlay
                            loop
                            muted
                            className="absolute inset-0 w-full h-full object-cover"
                        />
                        <div className="relative z-10 flex items-center justify-start h-full p-4">
                            <div className="text-left max-w-full">
                                <h1
                                    className="text-2xl sm:text-4xl md:text-4xl lg:text-7xl xl:text-8xl font-bold leading-tight ml-0 text-start mb-10 mt-24"
                                    style={{
                                        backgroundImage: 'linear-gradient(to right, #005aa7, #fffde4)',
                                        WebkitBackgroundClip: 'text',
                                        backgroundClip: 'text',
                                        color: 'transparent',
                                    }}
                                >
                                    <span className="block sm:hidden" style={{ fontSize: '1rem', lineHeight: '3.5rem' }}>
                                        Pak Hoardings!
                                        <br />
                                        <TypeAnimation
                                            sequence={[
                                                'Your Ad Space',
                                                1000,
                                                'Space to Influence',
                                                1000,
                                                'Reach Your Audience',
                                                1000,
                                            ]}
                                            wrapper="h6"
                                            cursor={true}
                                            repeat={Infinity}
                                        />
                                    </span>
                                    <span className="hidden sm:block" style={{ fontSize: '4.875rem', lineHeight: '6.25rem' }}>
                                        Pak Hoardings!
                                        <br />
                                        <TypeAnimation
                                            sequence={[
                                                'Your Ad Space',
                                                1000,
                                                'Space to Influence',
                                                1000,
                                                'Reach Your Audience',
                                                1000,
                                            ]}
                                            wrapper="h6"
                                            cursor={true}
                                            repeat={Infinity}
                                        />
                                    </span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </>
    );
}

export default AutoPlay;
