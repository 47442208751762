// src/App.jsx
import React, { useState } from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import Searchbar from './Components/HomePage/Searchbar';
import Breadcrumbs from './Components/HomePage/Breadcrumbs';
import Ads from './Components/HomePage/Ads';
import Counter from './Components/HomePage/Counter';
import Testimonial from './Components/HomePage/Testimonial';
import Login from './Authentication/Login';
import Signup from './Authentication/Signup';
import ResetPass from './Authentication/ResetPass';
import NewPassword from './Authentication/newPassword';
import Error from './Components/HomePage/404Page';
import Services from './Components/HomePage/Services';
import SingleItem from './Components/HomePage/SingleItem';
import Nav from './Components/HomePage/Nav';
import CateSlider from './Components/HomePage/CateSlider';
import BannerAd from './Components/HomePage/BannerAd';
import Footer from './Components/HomePage/Footer';
import Contact from './Components/HomePage/Contact';
import ScrollToTop from './Components/HomePage/ScrollToTop';
import Items from './Components/HomePage/Items';
import Backbutton from './Components/HomePage/Backbutton';
import AddForm from './Components/HomePage/AddForm';
import SidebarAdmin from './Components/Admin/Dashboard/SidebarAdmin';
import Categories from './Components/Admin/Categories/Categories';
import Products from './Components/Admin/Products/Products';
import AdminInactivePanel from './Components/Admin/InactiveAds/InactivePanel';
import RolePanel from './Components/Admin/RoleManag/RolePanel';
import UserSettings from './Components/Admin/UserSet/UserSettings';
import { Scrollbars } from 'react-custom-scrollbars';
import AdvertiserDashboard from './Components/Advertiser/Dashboard/dashboard';
import InactivePanel from './Components/Advertiser/InactiveAds/InactivePanel';
import Stripe from './Components/Advertiser/Stripe/StripeTry';
import AdvertiserUserSet from './Components/Advertiser/UserSettings/UsetSet';
import Pricing from './Components/Advertiser/Pricing/PricePanel';
import Postlisting from './Components/Advertiser/Postlist/PostPanel';
import FeaturedAdsAdvertiser from './Components/Advertiser/Featured/FeaturedPanel';
import ProtectedRoute from './Components/ProtectedRoute';
import UserDashboard from './Components/User/Dashboard/DashboardUser';
import FeaturedAdsPanel from './Components/User/Featured/FeaturedAdsPanel';
import UserPosting from './Components/User/Postlist/PostUser';
import UserPricing from './Components/User/Pricing/PricePanel';
import SettingsUser from './Components/User/UserSettings/UsetSet';
import UserInactiveAds from './Components/User/InactiveAds/InactivePanel';
import Img from './images/White Simple Light Blogger Bio Link Website.jpg';
import Nav2 from './Components/HomePage/Nav2';
import SubCategory from './Components/Admin/SubCategory/SubCategory';
import About from './Components/HomePage/About';
import PostAd from './Components/HomePage/PostAd';
import FAQs from './Components/HomePage/FAQs';
import Privacy from './Components/HomePage/Privacy';
import SideMenu from './Components/HomePage/SideMenu';


function App() {
  const [progress, setProgress] = useState(0);

  const router = createBrowserRouter([
    // Home Page Routing
    {
      path: "/",
      errorElement: <Error />,
      element: (
        <>
          <Nav />
          <SideMenu/>
          <Searchbar />
          <CateSlider />
          <PostAd/>
          <Ads />
          <Backbutton />
          <BannerAd />
          <Counter />
          <Testimonial />
          <Footer />
        </>
      ),
    },
    {
      path: "/add-post",
      errorElement: <Error />,
      element: (
        <>
          <ProtectedRoute>
            <ScrollToTop />
            <Nav />
            <AddForm setProgress={setProgress} />
            <Footer />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/services",
      errorElement: <Error />,
      element: (
        <>
          <ScrollToTop />
          <Nav />
          <SideMenu/>
          <Services setProgress={setProgress} />
          <Footer />
        </>
      ),
    },
    {
      path: "/contact",
      errorElement: <Error />,
      element: (
        <>
          <ScrollToTop />
          <Nav />
          <SideMenu/>
          <Contact setProgress={setProgress} />
          <Footer />
        </>
      ),
    },
    {
      path: "/all-items",
      errorElement: <Error />,
      element: (
        <>
          <ScrollToTop />
          <Nav2 />
          <Items setProgress={setProgress} />
          <Footer />
        </>
      ),
    },
    {
      path: "/all-items/singleItem/:id",
      errorElement: <Error />,
      element: (
        <>
          <ScrollToTop />
          <Nav2 />
          <SingleItem setProgress={setProgress} />
          <Footer />
        </>
      ),
    },
    // Authentication Routing
    {
      path: "/login",
      errorElement: <Error />,
      element: (
        <>
          <Login setProgress={setProgress} />
        </>
      ),
    },
    {
      path: "/signup",
      errorElement: <Error />,
      element: (
        <>
          <Signup setProgress={setProgress} />
        </>
      ),
    },
    {
      path: "/login/reset-password",
      errorElement: <Error />,
      element: (
        <>
          <ResetPass setProgress={setProgress} />
        </>
      ),
    },
    {
      path: "/login/reset-password/newPassword",
      errorElement: <Error />,
      element: (
        <>
          <NewPassword setProgress={setProgress} />
        </>
      ),
    },
    // Admin Panel Routing
    {
      path: "/admin/dashboard-panel",
      errorElement: <Error />,
      element: (
        <>
          <ProtectedRoute>
            <ScrollToTop />
            <SidebarAdmin />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/admin/categories",
      errorElement: <Error />,
      element: (
        <>
          <ProtectedRoute>
            <ScrollToTop />
            <Categories />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/admin/sub-category",
      errorElement: <Error />,
      element: (
        <>
          <ProtectedRoute>
            <ScrollToTop />
            <SubCategory />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/admin/archive-ads",
      errorElement: <Error />,
      element: (
        <>
          <ProtectedRoute>
            <ScrollToTop />
            <AdminInactivePanel />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/admin/role-management",
      errorElement: <Error />,
      element: (
        <>
          <ProtectedRoute>
            <ScrollToTop />
            <RolePanel />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/admin/products",
      errorElement: <Error />,
      element: (
        <>
          <ProtectedRoute>
            <ScrollToTop />
            <Products />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/admin/user-settings",
      errorElement: <Error />,
      element: (
        <>
          <ProtectedRoute>
            <ScrollToTop />
            <UserSettings />
          </ProtectedRoute>
        </>
      ),
    },
    // Advertiser Panel Routing
    {
      path: '/advertiser/panel',
      errorElement: <Error />,
      element: (
        <>
          <ProtectedRoute>
            <ScrollToTop />
            <AdvertiserDashboard />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/advertiser/user-settings",
      errorElement: <Error />,
      element: (
        <ProtectedRoute>
          <ScrollToTop />
          <AdvertiserUserSet />
        </ProtectedRoute>
      ),
    },
    {
      path: "/advertiser/pricing",
      errorElement: <Error />,
      element: (
        <ProtectedRoute>
          <ScrollToTop />
          <Pricing />
        </ProtectedRoute>
      ),
    },
    {
      path: "/advertiser/post-listing",
      errorElement: <Error />,
      element: (
        <ProtectedRoute>
          <ScrollToTop />
          <Postlisting />
        </ProtectedRoute>
      ),
    },
    {
      path: "/advertiser/archive-ads",
      errorElement: <Error />,
      element: (
        <ProtectedRoute>
          <ScrollToTop />
          <InactivePanel />
        </ProtectedRoute>
      ),
    },
    {
      path: "/advertiser/featured-ads",
      errorElement: <Error />,
      element: (
        <ProtectedRoute>
          <ScrollToTop />
          <FeaturedAdsAdvertiser />
        </ProtectedRoute>
      ),
    },
    // {
    //   path: "/advertiser/Stripe",
    //   errorElement: <Error />,
    //   element: (
    //     <ProtectedRoute>
    //       <ScrollToTop />
    //       <Stripe />
    //     </ProtectedRoute>
    //   ),
    // },
    // User Panel Routing
    {
      path: "/user/panel",
      errorElement: <Error />,
      element: (
        <>
          <ProtectedRoute>
            <ScrollToTop />
            <UserDashboard />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/user/featured-ads",
      errorElement: <Error />,
      element: (
        <ProtectedRoute>
          <ScrollToTop />
          <FeaturedAdsPanel />
        </ProtectedRoute>
      ),
    },
    {
      path: "/user/pricing",
      errorElement: <Error />,
      element: (
        <>
          <ProtectedRoute>
            <ScrollToTop />
            <UserPricing />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/user/post-listing",
      errorElement: <Error />,
      element: (
        <>
          <ProtectedRoute>
            <ScrollToTop />
            <UserPosting />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/user/archive-ads",
      errorElement: <Error />,
      element: (
        <>
          <ProtectedRoute>
            <ScrollToTop />
            <UserInactiveAds />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/user/user-settings",
      errorElement: <Error />,
      element: (
        <>
          <ProtectedRoute>
            <ScrollToTop />
            <SettingsUser />
          </ProtectedRoute>
        </>
      ),
    },
    {
      path: "/about-us",
      errorElement: <Error />,
      element: (
        <>
          <ScrollToTop />
          <Nav />
          <SideMenu/>
          <About setProgress={setProgress} />
          <Footer />
        </>
      ),
    },
    {
      path: "/FAQs",
      errorElement: <Error />,
      element: (
        <>
          <ScrollToTop />
          <Nav2 />
          <SideMenu/>
          <FAQs setProgress={setProgress} />
          <Footer />
        </>
      ),
    },
    
    {
      path: "/privacy-policy",
      errorElement: <Error />,
      element: (
        <>
          <ScrollToTop />
          <Nav2 />
          <Privacy setProgress={setProgress} />
          <Footer />
        </>
      ),
    },
    
  ]);

  return (
    <div
      className="App"
    >
      <LoadingBar
        color='#2563eb'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <RouterProvider router={router} />
      <Backbutton />
    </div>
  );
}

export default App;
