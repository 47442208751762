import React, { useState } from 'react';

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="py-24">
      <div className="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
        <div className="mb-16 mt-12">
          <h6 className="text-lg text-[#1C64F2] font-medium text-center mb-2">FAQs</h6>
          <h2 className="text-4xl font-manrope text-center font-bold text-gray-900 leading-[3.25rem]">
            Frequently asked questions
          </h2>
        </div>

        <div className="accordion-group">
          {[
            { question: 'What locations are available for hoarding advertisements?', answer: 'We offer high-traffic locations across the city to maximize visibility for your brand. Contact us to learn about available spots in your preferred areas.' },
            { question: 'What sizes of hoarding spaces do you offer?', answer: 'Our hoarding sizes range from small to extra-large to fit your advertising needs. We’ll help you choose the size that suits your goals and budget.' },
            { question: 'How much does hoarding advertising cost?', answer: 'Pricing depends on the location, size, and duration of the ad. Reach out for a customized quote that matches your campaign’s needs.' },
            { question: 'Can I customize my ad design?', answer: 'Absolutely! You can provide your own design, or our team of designers can help create an impactful, eye-catching ad tailored to your brand.' },
            { question: 'How long can I display my ad? ', answer: 'Display durations are flexible, ranging from weekly to monthly plans. Let us know how long you’d like your ad to stay up, and we’ll make it happen.' },
            {
              question:
                'What’s the process to get started with my hoarding ad?', answer: 'It’s easy! Contact us with your requirements, choose your preferred location, provide the ad design (or let us design it for you), and we’ll handle the rest from setup to display.'
            },

          ].map((faq, index) => (
            <div
              key={index}
              className={`accordion py-8 px-6 border-b border-gray-200 transition-all duration-500 rounded-2xl hover:bg-indigo-50 ${activeIndex === index ? 'bg-indigo-50' : ''}`}
            >
              <button
                className="accordion-toggle group inline-flex items-center justify-between w-full text-left text-gray-900 transition duration-500 hover:text-[#1C64F2]"
                onClick={() => toggleAccordion(index)}
                aria-expanded={activeIndex === index}
              >
                <h5>{faq.question}</h5>
                <svg
                  className={`text-gray-500 transition-transform duration-500 ${activeIndex === index ? 'rotate-180 text-[#1C64F2]' : ''}`}
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              {activeIndex === index && (
                <div className="accordion-content w-full px-0 overflow-hidden transition-max-height duration-500" style={{ maxHeight: activeIndex === index ? '200px' : '0' }}>
                  <p className="text-base text-gray-900 leading-6">
                    {faq.answer}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQs;
