import React, { useState } from 'react';
import { MdCancel } from "react-icons/md";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Cookies from 'js-cookie';
import axios from 'axios';

const stripePromise = loadStripe('pk_test_51NIWXEFC1gRl8evvNSkKRtEyaLPaR77JNT12rI1C8rBgVQOjpWHTChfDT0iGQAUZFuZ7nE3rTbvr1HVCU7qK93WI00VhGYeirI');

const CheckoutForm = ({ onClose, plan }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [customerId, setCustomerId] = useState(null);
    const [email, setEmail] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [emailError, setEmailError] = useState('');
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showDangerAlert, setShowDangerAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const getToken = () => {
        return Cookies.get('token');
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        if (!value.match(/^[^\s@]+@gmail\.com$/)) {
            setEmailError('Please enter a valid email address ending with @gmail.com');
        } else {
            setEmailError('');
        }
    };

    const handlePostalCodeChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        setPostalCode(value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error: methodError, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (methodError) {
            setError(methodError.message);
            return;
        }

        await createCustomer();

        const token = getToken();
        const amountInPaise =
            plan === 'Basic' ? 2500 :
                plan === 'Premium' ? 3500 :
                    plan === 'Hot' ? 4500 : 0; // 2500 paise = 25 INR, 3500 paise = 35 INR, 4500 paise = 45 INR
        const { data } = await axios.post(
            'https://techcogads.com/api/payments/create-payment-intent',
            {
                amount: amountInPaise,
                customerId: customerId,
                paymentMethodId: paymentMethod.id
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );

        const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(data.clientSecret);

        if (confirmError) {
            setError(confirmError.message);
        } else if (paymentIntent.status === 'succeeded') {
            setSuccess(true);
        }
    };
    const token = getToken();
    const createCustomer = async () => {
        const response = await axios.post('https://techcogads.com/api/Payments/create-customer', {
            name: "Customer Name", // Replace with actual customer name
            email: email, // Use the email from the form
            headers: {
                Authorization: `Bearer ${token}`,

            }

        });

        setCustomerId(response.data.CustomerId);
    };
    console.log("TokenPayment", token)
    return (

        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 font-arapey">
            <div className="bg-white rounded-lg shadow-lg p-8 w-96">
                <div className="flex justify-end">
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <MdCancel size={24} />
                    </button>
                </div>
                <h2 className="text-2xl font-semibold mb-4">Checkout</h2>
                {error && <div className="text-red-500 mb-4">{error}</div>}
                {success ? (
                    <div className="text-green-500">Payment succeeded!</div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700">Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                            {emailError && <div className="text-red-500 text-sm">{emailError}</div>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Postal Code</label>
                            <input
                                type="text"
                                value={postalCode}
                                onChange={handlePostalCodeChange}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Card Details</label>
                            <CardElement className="w-full px-3 py-2 border rounded" />
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-700 transition duration-300"
                        >
                            Pay
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};


const Pricing = () => {
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);

    const handleGetStartedClick = (plan) => {
        setSelectedPlan(plan);
        setShowPaymentForm(true);
    };

    const handleCloseForm = () => {
        setShowPaymentForm(false);
    };


    return (
        <div>
            <section className="bg-white dark:bg-gray-900 font-arapey">
                <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-8 lg:px-6">
                    <div className="text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-white">Payment Plans</h2>
                        <p className="mt-4 text-lg leading-6 text-gray-500 dark:text-gray-400">
                            Choose a plan that best suits your needs.
                        </p>
                    </div>
                    <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0 mt-10">
                        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100  dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                            <h3 className="mb-4 text-2xl font-semibold">Standard Plan</h3>
                            <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                                Best option for personal use & for your next project.
                            </p>
                            <div className="flex justify-center items-baseline my-8">
                                <span className="mr-2 text-5xl font-extrabold">$25</span>
                                <span className="text-gray-500 dark:text-gray-400">/month</span>
                            </div>
                            <ul role="list" className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    <svg
                                        className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                    <span>Ads Limit: 10</span>
                                </li>
                                {/* <li className="flex items-center space-x-3">
                                    <svg
                                        className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                    <span>Premium support: 6 months</span>
                                </li> */}
                            </ul>
                            <button
                                onClick={() => handleGetStartedClick('Basic')}
                                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                            >
                                Buy Now
                            </button>
                        </div>
                        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                            <h3 className="mb-4 text-2xl font-semibold">Premium Plan</h3>
                            <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                                Best option for personal use & for your next project.
                            </p>
                            <div className="flex justify-center items-baseline my-8">
                                <span className="mr-2 text-5xl font-extrabold">$35</span>
                                <span className="text-gray-500 dark:text-gray-400">/month</span>
                            </div>
                            <ul role="list" className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    <svg
                                        className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                    <span>Ads Limit: 20</span>
                                </li>
                                {/* <li className="flex items-center space-x-3">
                                    <svg
                                        className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                    <span>Premium support: 6 months</span>
                                </li> */}
                            </ul>
                            <button
                                onClick={() => handleGetStartedClick('Premium')}
                                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                            >
                                Buy Now
                            </button>
                        </div>
                        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                            <h3 className="mb-4 text-2xl font-semibold">Hot Plan</h3>
                            <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                                Best option for personal use & for your next project.
                            </p>
                            <div className="flex justify-center items-baseline my-8">
                                <span className="mr-2 text-5xl font-extrabold">$45</span>
                                <span className="text-gray-500 dark:text-gray-400">/month</span>
                            </div>
                            <ul role="list" className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    <svg
                                        className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                    <span>Ads Limit: 30</span>
                                </li>
                                {/* <li className="flex items-center space-x-3">
                                    <svg
                                        className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                    <span>Free updates: 24 months</span>
                                </li> */}
                            </ul>
                            <button
                                onClick={() => handleGetStartedClick('Hot')}
                                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                            >
                                Buy Now
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            {showPaymentForm && (
                <Elements stripe={stripePromise}>
                    <CheckoutForm onClose={handleCloseForm} plan={selectedPlan} />
                </Elements>
            )}
        </div>
    );
};


export default Pricing;



{/* <div className="w-full dark:bg-gray-700 flex justify-center items-center">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:py-24 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-white">Pricing Plan:
            statistics</h2>
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-4 mt-4">
            <div className="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
                <div className="px-4 py-5 sm:p-6">
                    <dl>
                        <dt className="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                            Ads Limit
                        </dt>
                        <dd className="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">10</dd>
                    </dl>
                </div>
            </div>
            <div className="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
                <div className="px-4 py-5 sm:p-6">
                    <dl>
                        <dt className="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                            Ads Posted
                        </dt>
                        <dd className="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">20</dd>
                    </dl>
                </div>
            </div>
            <div className="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
                <div className="px-4 py-5 sm:p-6">
                    <dl>
                        <dt className="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                            Ads Remaining
                        </dt>
                        <dd className="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">30</dd>
                    </dl>
                </div>
            </div>
            <div className="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
                <div className="px-4 py-5 sm:p-6">
                    <dl>
                        <dt className="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">Total users
                        </dt>
                        <dd className="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">166.89
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</div> */}