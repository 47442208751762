import React from 'react';
import { Link } from 'react-router-dom';
import imgSrc from '../../images/billboard.png'; // Ensure correct path to your image
import './Category.css';

const CateSlider = () => {
    return (<>
   
        <div className="mt-9 mx-auto max-w-4xl pb-[70px] flex flex-col md:flex-row items-center"> {/* Set a max width */}
            {/* Left-aligned Heading */}
            <div className="md:w-1/4 mt-[40px] flex flex-col justify-center items-center h-full text-center">
                <h2 className="text-[26px] font-bold text-gray-900 leading-[3.25rem] ">
                    
                    <span className="bg-clip-text text-gray-700"> Categories</span>
                </h2>
                <Link to={`/all-items`} className="text-blue-500  ">
                    View All Categories
                </Link>
            </div>

            {/* Circular Cards Section */}
            <div className="w-full md:w-3/4 mt-8 md:mt-0"> {/* Added top margin for small screens */}
                <section id="cards" className="cards section flex flex-wrap justify-evenly"> {/* Changed to flexbox with wrap */}
                    <div className="card bg-slate-600 m-4 flex flex-col items-center p-4 rounded-lg shadow-md"> {/* Added margin, padding, rounded corners, and shadow */}
                        <img src={imgSrc} alt="Card Icon" className="card-image mb-2" />
                        <h4 className="card-title text-white text-lg">Lit Billboard</h4>
                    </div>
                    <div className="card bg-red-500 m-4 flex flex-col items-center p-4 rounded-lg shadow-md">
                        <img src={imgSrc} alt="Card Icon" className="card-image mb-2" />
                        <h4 className="card-title text-white text-lg">Unlit Billboard</h4>
                    </div>
                    <div className="card bg-blue-500 m-4 flex flex-col items-center p-4 rounded-lg shadow-md">
                        <img src={imgSrc} alt="Card Icon" className="card-image mb-2" />
                        <h4 className="card-title text-white text-lg">Digital Billboard</h4>
                    </div>
                    <div className="card bg-yellow-300 m-4 flex flex-col items-center p-4 rounded-lg shadow-md">
                        <img src={imgSrc} alt="Card Icon" className="card-image mb-2" />
                        <h4 className="card-title text-gray-800 text-lg">Digital Streamers</h4>
                    </div>
                </section>
            </div>
        </div>
        <div className="container my-4">
            <hr className="border-t border-gray-300 w-[60%] mx-auto" />
        </div>
    </>
    );
};

export default CateSlider;
