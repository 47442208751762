import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Img from '../images/sign-up.png';
import logo from '../images/header-logo-dark.png';

const Signup = ({ setProgress }) => {
    const [formData, setFormData] = useState({
        username: '',
        phoneNo: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: ''
    });
    const [errors, setErrors] = useState({});
    const [successAlert, setSuccessAlert] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const resetForm = () => {
        setFormData({
            username: '',
            phoneNo: '',
            email: '',
            password: '',
            confirmPassword: '',
            role: ''
        });
        setErrors({});
        setSuccessAlert('');
        setMessage('');
        setError('');
    };

    const validateUsername = (username) => {
        if (username.length < 3) {
            setErrors(prevErrors => ({ ...prevErrors, username: 'Username must be at least 3 characters long.' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, username: '' }));
        }
    };

    const validatePhoneNo = (phoneNo) => {
        const phoneRegex = /^03\d{9}$/;
        if (!phoneRegex.test(phoneNo)) {
            setErrors(prevErrors => ({ ...prevErrors, phoneNo: 'Phone number must contain 11 digits and start with 03.' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, phoneNo: '' }));
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setErrors(prevErrors => ({ ...prevErrors, email: 'Invalid email address.' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, email: '' }));
        }
    };

    const validatePassword = (password) => {
        if (password.length < 6) {
            setErrors(prevErrors => ({ ...prevErrors, password: 'Password must be at least 6 characters long.' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, password: '' }));
        }
    };

    const validateConfirmPassword = (confirmPassword) => {
        if (confirmPassword !== formData.password) {
            setErrors(prevErrors => ({ ...prevErrors, confirmPassword: 'Passwords do not match.' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, confirmPassword: '' }));
        }
    };

    const handleRoleSelection = (selectedRole) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            role: selectedRole
        }));
        setDropdownOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: name === 'phoneNo' ? value.toString() : value
        }));

        if (name === 'username') validateUsername(value);
        if (name === 'phoneNo') validatePhoneNo(value);
        if (name === 'email') validateEmail(value);
        if (name === 'password') validatePassword(value);
        if (name === 'confirmPassword') validateConfirmPassword(value);
    };

    const handleSignup = async (e) => {
        e.preventDefault();
        if (!Object.values(errors).some(error => error !== '')) {
            try {
                const response = await axios.post('https://techcogads.com/api/Auth/register', formData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.status === 200) {
                    // Store form data in localStorage (excluding password and confirmPassword)
                    const { password, confirmPassword, ...dataToStore } = formData;
                    localStorage.setItem('signupData', JSON.stringify(dataToStore));

                    resetForm();
                    setMessage('Signup successful! Please login.');

                    // Show the message for 3 seconds before redirecting
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000);
                } else {
                    const errorData = response.data;
                    throw new Error(`Error: ${errorData.message}`);
                }
            } catch (error) {
                if (error.response && error.response.status === 409) {
                    setError('Email is already in use.');
                } else {
                    setError('Signup failed. Please check your details and try again.');
                }
                console.error('Signup Error:', error);
                setTimeout(() => {
                    setError('');
                }, 3000); // Clear error after 3 seconds
            }
        }
    };


    return (
        <section className='font-abc mt-4'>
            <div className="container mx-auto max-w-screen-xl flex flex-col md:flex-row">
                <div className="hidden md:block md:w-1/2 lg:flex justify-center md:justify-start">
                    <img className="py-8 "  src={Img} alt="logo" />
                </div>

                <div className="md:w-1/2">
                    <div className="flex flex-col items-center justify-center px-6 py-7 mx-auto md:h-screen lg:py-0 mt-10 mb-20">
                        <a className="flex items-center mb-4 mt-24 text-2xl font-semibold text-gray-900 dark:text-white">
                            <img className="w-13 h-24" src={logo} alt="logo" />
                        </a>
                        <div className="w-full mb-20 bg-white rounded-lg dark:border md:mt-0 sm:max-w-md sm:mt-4 xl:p-0 dark:bg-gray-800 dark:border-gray-700 shadow-2xl shadow-blue-500/70">
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                    Create an account
                                </h1>
                                <form className="space-y-4 md:space-y-6" onSubmit={handleSignup}>
                                    <div>
                                        <label htmlFor="username" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                                        <input
                                            type="text"
                                            name="username"
                                            id="username"
                                            value={formData.username}
                                            onChange={handleChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Enter your username"
                                            required
                                        />
                                        {errors.username && (
                                            <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                {errors.username}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="phoneNo" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                                        <input
                                            type="tel"
                                            name="phoneNo"
                                            id="phoneNo"
                                            value={formData.phoneNo}
                                            onChange={handleChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Enter your phone number"
                                            required
                                        />
                                        {errors.phoneNo && (
                                            <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                {errors.phoneNo}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                        <input
                                            type="email"
                                            autoComplete="username"
                                            name="email"
                                            id="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="name@company.com"
                                            required
                                        />
                                        {errors.email && (
                                            <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                {errors.email}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="password" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Enter your password"
                                            required
                                        />
                                        {errors.password && (
                                            <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                {errors.password}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="confirmPassword" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Confirm Password</label>
                                        <input
                                            type="password"
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            value={formData.confirmPassword}
                                            onChange={handleChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Re-enter your password"
                                            required
                                        />
                                        {errors.confirmPassword && (
                                            <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                {errors.confirmPassword}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="role" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Role</label>
                                        <div className="relative">
                                            <button
                                                type="button"
                                                onClick={() => setDropdownOpen(!dropdownOpen)}
                                                aria-haspopup="listbox"
                                                aria-expanded="true"
                                                className="w-full p-2.5 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 flex items-center justify-between"
                                            >
                                                {formData.role || 'Select Role'}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 text-gray-500"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </button>
                                            {dropdownOpen && (
                                                <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10">
                                                    <ul
                                                        tabIndex="-1"
                                                        role="listbox"
                                                        aria-labelledby="listbox-label"
                                                        aria-activedescendant="listbox-option-3"
                                                        className="py-1 overflow-auto text-base ring-1 ring-black ring-opacity-5 max-h-60 rounded-md shadow-xs focus:outline-none sm:text-sm"
                                                    >
                                                        <li
                                                            onClick={() => handleRoleSelection('Advertiser')}
                                                            className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                                        >
                                                            <div className="flex items-center">
                                                                <span className="ml-3 block font-normal truncate">Advertiser</span>
                                                            </div>
                                                        </li>
                                                        <li
                                                            onClick={() => handleRoleSelection('User')}
                                                            className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                                        >
                                                            <div className="flex items-center">
                                                                <span className="ml-3 block font-normal truncate">User</span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="w-full bg-primary-600 hover:bg-primary-700 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-opacity-50 transition-all ease-in-out duration-300"
                                        >
                                            Create Account
                                        </button>
                                    </div>
                                </form>
                                {message && (
                                    <div className="flex items-center p-2 mt-2 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-green-700 dark:text-green-200" role="alert">
                                        {message}
                                    </div>
                                )}
                                {error && (
                                    <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-red-700 dark:text-red-200" role="alert">
                                        {error}
                                    </div>
                                )}
                                <div className="flex items-center justify-center mt-6">
                                    <span className="mr-2">Already have an account?</span>
                                    <Link to="/login" className="text-blue-600 hover:underline dark:text-blue-400">
                                        Log in
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Signup;
