import React from 'react';
import footerLogo from '../../images/header-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
const Footer = () => {

    // For Button Routing
    const Navigate = useNavigate();

    return (
        <>
            <footer className="bg-custom-gradient font-arapey scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700">
                <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                    <div className="md:flex md:justify-between">
                        <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                            <a class=" mt-3 flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                                <img src={footerLogo} className="h-[80px] w-18" alt="Logo" />
                            </a>
                            <p class="mt-4 text-sm text-gray-500">
                                Where Brands meet <br/> Hoarding Owners for <br/> unmatched Exposure.
                            </p>
                        </div>
                      
                        <div className="grid grid-cols-2 gap-8 sm:gap-2 sm:grid-cols-4 mt-3">
                            <div>
                                <h2 className="mb-6 text-sm font-semibold  uppercase text-white">Quick Links</h2>
                                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                                    <li className="mb-2">
                                        <Link to="/" className="hover:underline hover:text-blue-400">Home</Link>
                                    </li>
                                    <li className="mb-2">
                                        <Link to="/about-us" className="hover:underline hover:text-blue-400">About Us</Link>
                                    </li>
                                    <li className="mb-2">
                                        <Link to="/services" className="hover:underline hover:text-blue-400">Services</Link>
                                    </li>
                                    <li className="mb-2">
                                        <Link to="/contact" className="hover:underline hover:text-blue-400">Contact Us</Link>
                                    </li>
                                  
                                </ul>
                            </div>
                            <div>
                                <h2 className="mb-6 text-sm font-semibold  uppercase text-white">Our Partners</h2>
                                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                                    <li className="mb-4">
                                        <a href="https://techcogg.com/" target='_blank' className="hover:underline hover:text-blue-400">Techcog (PVT) Ltd</a>
                                    </li>
                                    <li className="mb-4">
                                        <a href="https://bizzstats.com/" target='_blank' className="hover:underline hover:text-blue-400">Bizz Stats</a>
                                    </li>
                                    <li className="mb-4">
                                        <a href="https://inventorycog.com/" target='_blank' className="hover:underline hover:text-blue-400">Inventory Cog</a>
                                    </li>
                                    <li className="mb-4">
                                        <a  target='_blank' className="hover:underline hover:text-blue-400">Amal & Aiman- <br/>Clothing Brand</a>
                                    </li>
                                    <li className="mb-4">
                                        <a href="http://103.168.40.19:8080/ords/f?p=2007:LOGIN:17216527649889:::::" target='_blank' className="hover:underline hover:text-blue-400">Abshar BNS</a>
                                    </li>
                                  
                                </ul>
                            </div>
                            <div>
                                <h2 className="mb-6 text-sm font-semibold uppercase text-white">Reach us</h2>
                                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                                    <li className="mb-4">
                                        <a href="https://mail.google.com/mail/u/0/#inbox" target='_blank' className="hover:underline hover:text-blue-400">
                                            <span className="block sm:inline">pakhoardings2024<br/></span>
                                            <span className="block sm:inline">@gmail.com</span>
                                        </a>
                                    </li>
                                    <li className="mb-4">
                                        <a className="hover:underline hover:text-blue-400">(+92) 321 1115936</a>
                                    </li>
                                    <li className="mb-4">
                                        <a href="https://maps.app.goo.gl/XCGE4CVmkJozFmjj8" target='_blank' className="hover:underline hover:text-blue-400">98-J2 WapdaTown Lahore Pakistan</a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="mb-6 text-sm font-semibold  uppercase text-white">Help</h2>
                                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                                    <li className="mb-4">
                                        <Link to="/FAQs"  className="hover:underline hover:text-blue-400">FAQs</Link>
                                    </li>
                                    <li className="mb-4">
                                        <Link to="/privacy-policy"  className="hover:underline hover:text-blue-400">Privacy & Policy</Link>
                                    </li>
                                  
                                </ul>
                            </div>
                            {/* <div>
                                <button type="button" onClick={() => Navigate("/contact")} className="hover:scale-110 duration-200 px-3 font-thin bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-xl text-white hover:from-blue-700 hover:via-purple-700 hover:to-pink-700 ">
                                    GET A QUOTE
                                </button>
                            </div> */}
                            
                        </div>
                    </div>
                    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-500 lg:my-8" />
                    <div className="sm:flex sm:items-center sm:justify-between px-3">
                        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="#" className="hover:underline hover:text-blue-400">PakHoardings™</a>.<a href='https://techcogg.com/'  target='_blank' className="hover:underline hover:text-blue-400">Powered by Techcog</a> .
                        </span>
                        <div className="flex mt-4 sm:justify-center sm:mt-0">
                            <a className="ms-5 text-gray-500" href="https://www.facebook.com/people/Pak-Hoardings/pfbid02FHyQe47VgNwLcX5zTapSeEygCC3PQCvArieKen8bMAZ4jX25RYeEXKz63Se4rdg7l/" target='_blank'>
                                <svg className="w-5 h-5 hover:scale-150 duration-200 hover:stroke-blue-600 hover:text-blue-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                                    <FaFacebookF />
                                </svg>
                                <span className="sr-only">Facebook page</span>
                            </a>
                            <a href="https://www.instagram.com/pak_hoardings/" target='_blank' className="text-gray-500 ms-5">
                                <svg className="w-5 h-5 hover:scale-150 duration-200 hover:stroke-pink-500 hover:text-pink-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 21 16">
                                    <FaInstagram />
                                </svg>
                                <span className="sr-only">Instagram Page</span>
                            </a>
                            <a href="https://www.linkedin.com/feed/?trk=onboarding-landing" target='_blank' className="text-gray-500 ms-5 mr-6">
                                <svg className="w-5 h-5 hover:scale-150 duration-200 hover:stroke-blue-700 hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 17">
                                    <FaLinkedin />
                                </svg>
                                <span className="sr-only">Linkedin page</span>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;