import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Cookies from 'js-cookie';
import { CgDetailsMore } from "react-icons/cg";
import { MdAirplanemodeInactive } from "react-icons/md";
import { FaRegSmileWink } from "react-icons/fa";

const InactiveAds = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [showReactiveModal, setShowReactiveModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [form, setForm] = useState({
        name: '',
        priceOptions: '',
        price: '',
        mobileNo: '',
        categoryId: '',
        size: '',
        trafficComingFrom: '',
        trafficGoingTowards: '',
        description: '',
        availabiltyDate: '',
        image: null,
        location: '',
    });
    const [viewMode, setViewMode] = useState(true); // Initialize viewMode as true for view-only mode
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showDangerAlert, setShowDangerAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const fileInputRef = useRef(null);

    const getToken = () => {
        return Cookies.get('token');
    };

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                const geocoder = new window.google.maps.Geocoder();
                const latLng = { lat: latitude, lng: longitude };

                geocoder.geocode({ location: latLng }, (results, status) => {
                    if (status === 'OK' && results[0]) {
                        const address = results[0].formatted_address;
                        setForm({ ...form, location: address });
                    } else {
                        console.error('Geocode was not successful for the following reason: ' + status);
                    }
                });
            }, (error) => {
                console.error('Error occurred. Error code: ' + error.code);
            });
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };



    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const token = getToken();
                const response = await axios.get('https://techcogads.com/api/Products/GetDeletedProducts', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://techcogads.com/api/Categories/GetAllCategories');
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchProducts();
        fetchCategories();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    const handleUpdateProduct = async () => {
        const token = getToken();

        if (!Number.isInteger(Number(form.categoryId))) {
            console.error('Invalid categoryId:', form.categoryId);
            return;
        }

        const formData = new FormData();
        formData.append('productId', selectedProduct.productId);
        formData.append('name', form.name);
        formData.append('price', form.price);
        formData.append('categoryId', form.categoryId);
        formData.append('description', form.description);
        formData.append('mobileNo', form.mobileNo);

        if (fileInputRef.current.files.length > 0) {
            formData.append('image', fileInputRef.current.files[0]);
        }

        try {
            await axios.put(`https://techcogads.com/api/Products/UpdateProductById/${selectedProduct.productId}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setShowSuccessAlert(true);
            setAlertMessage('Product updated successfully.');
            setTimeout(() => {
                setShowSuccessAlert(false);
                setAlertMessage('');
            }, 3000);
            window.location.reload();
            toggleModal();
        } catch (error) {
            setShowDangerAlert(true);
            setAlertMessage('Failed to update product. Please try again.');
            setTimeout(() => {
                setShowDangerAlert(false);
                setAlertMessage('');
            }, 3000);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        const token = getToken();

        const updatedProduct = {
            productId: selectedProduct.productId,
            name: form.name,
            price: form.price,
            categoryId: form.categoryId,
            description: form.description,
            mobileNo: form.mobileNo
        };

        const formData = new FormData();
        formData.append('productId', selectedProduct.productId);
        formData.append('name', updatedProduct.name);
        formData.append('price', updatedProduct.price);
        formData.append('categoryId', updatedProduct.categoryId);
        formData.append('description', updatedProduct.description);
        formData.append('mobileNo', updatedProduct.mobileNo);

        if (fileInputRef.current.files.length > 0) {
            formData.append('image', fileInputRef.current.files[0]);
        }

        try {
            await axios.put(`https://techcogads.com/api/Products/UpdateProductById/${selectedProduct.productId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setShowSuccessAlert(true);
            setAlertMessage('Product updated successfully.');
            setTimeout(() => {
                setShowSuccessAlert(false);
                setAlertMessage('');
            }, 3000);
            window.location.reload();
            toggleModal();
        } catch (error) {
            setShowDangerAlert(true);
            setAlertMessage('Failed to update product. Please try again.');
            setTimeout(() => {
                setShowDangerAlert(false);
                setAlertMessage('');
            }, 3000);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await handleUpdateProduct();
    };


    const handleDelete = async (productId) => {
        try {
            const token = getToken();
            await axios.post(`https://techcogads.com/api/Products/ActivateProduct/${productId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setShowReactiveModal(false);
            setShowSuccessAlert(true);
            setAlertMessage('Product Activated successfully.');
            setTimeout(() => {
                setShowSuccessAlert(false);
                setAlertMessage('');
            }, 3000);
            window.location.reload();
        } catch (error) {
            setShowDangerAlert(true);
            setAlertMessage('Failed to Active product. Please try again.');
        }
    };

    const openDetailsModal = (product) => {
        setSelectedProduct(product);
        setForm({
            name: product.name || '',
            price: product.price ? product.price.toString() : '',
            categoryId: product.categoryId ? product.categoryId.toString() : '',
            mobileNo: product.mobileNo ? product.mobileNo.toString() : '',
            description: product.description || ''
        });
        setViewMode(true); // Set view mode to true to disable inputs
        setIsOpen(true);
    };

    const openReactiveModal = (product) => {
        setSelectedProduct(product);
        setShowReactiveModal(true);
    };

    const closeReactiveModal = () => {
        setShowReactiveModal(false);
        setSelectedProduct(null);
    };

    const toggleModal = () => {
        setIsOpen(!isOpen);
        setSelectedProduct(null);
        setForm({
            name: '',
            price: '',
            description: '',
            categoryId: '',
            mobileNo: ''
        });
        setViewMode(false); // Reset view mode when closing modal
    };

    const handleRoleSelection = (Price) => {
        setForm(prevFormData => ({
            ...prevFormData,
            priceOptions: Price
        }));
        setDropdownOpen(false);
    };

    return (
        <div className="relative overflow-x-auto font-arapey">
            <section className="bg-white dark:bg-gray-900 hidden sm:block">
                <div className="px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-md text-center">
                        <h2 className="text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Non Active Ads Appear Here</h2>
                    </div>
                </div>
            </section>

            {/* Alerts */}
            {showSuccessAlert && (
                <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white text-center py-2 z-50">
                    {alertMessage}
                </div>
            )}

            {showDangerAlert && (
                <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-red-400 via-red-500 to-red-600 text-white text-center py-2 z-50">
                    {alertMessage}
                </div>
            )}

            {/* Product Table */}
            {products.length === 0 ? (
                <div>
                    <section className="bg-white dark:bg-gray-900">
                        <div className="px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                            <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">No Inactive Ad</h2>
                                <div className="flex items-center justify-center">
                                    <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">Deleted ads appear here for future reactivation.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            ) : (
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">Name</th>
                            <th scope="col" className="px-6 py-3">Category</th>
                            <th scope="col" className="px-6 py-3">Price</th>
                            <th scope="col" className="px-6 py-3">Mobile No</th>
                            <th scope="col" className="px-6 py-3">Image</th>
                            <th scope="col" className="px-6 py-3">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product) => (
                            <tr key={product.productId} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <td className="px-6 py-4">{product.name}</td>
                                <td className="px-6 py-4">{product.categoryName}</td>
                                <td className="px-6 py-4">{product.price}</td>
                                <td className="px-6 py-4">{product.mobileNo}</td>
                                <td className="px-6 py-4"><img src={`https://techcogads.com/${product.imagePath}`} alt={product.name} className="w-20 h-20 object-cover" /></td>
                                <td className="px-6 py-4">
                                    <button onClick={() => openReactiveModal(product)} className="text-green-500 hover:text-green-700 ml-1 text-lg">
                                        <MdAirplanemodeInactive className='' />
                                    </button>
                                    <button onClick={() => openDetailsModal(product)} className="text-[#ffb703] hover:text-yellow-400 ml-2 text-lg">
                                        <CgDetailsMore />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {/* ReActive Confirmation Modal */}
            {showReactiveModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg">
                        <p>Are you sure you want to ReActive this Ad?</p>
                        <div className="mt-4">
                            <button
                                onClick={() => handleDelete(selectedProduct.productId)}
                                className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                            >
                                Active
                            </button>
                            <button
                                onClick={closeReactiveModal}
                                className="bg-gray-300 text-black px-4 py-2 rounded"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Archive Modal */}
            {isOpen && (
                <div className="overflow-y-scroll fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="mt-96 bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
                        <h2 className="text-lg font-semibold mb-4">{selectedProduct ? 'Edit Product' : 'Add Product'}</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                    Hoarding Name
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={form.name}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    required
                                    disabled={viewMode} // Disable input in view mode
                                />
                            </div>
                            <div className="relative mb-4">
                                <label htmlFor="size" className="block text-sm font-medium text-gray-700">
                                    Payment Plan
                                </label>
                                <button
                                    type="button"
                                    id="priceOptions"
                                    name="priceOptions"
                                    value={form.priceOptions}
                                    onChange={handleInputChange}
                                    onClick={() => setDropdownOpen(!dropdownOpen)}
                                    aria-haspopup="listbox"
                                    aria-expanded="true"
                                    className="w-full p-2.5 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 flex items-center justify-between"
                                >
                                    {form.priceOptions || 'Select Pricing Options'}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-gray-500"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                                {dropdownOpen && (
                                    <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10">
                                        <ul
                                            tabIndex="-1"
                                            role="listbox"
                                            aria-labelledby="listbox-label"
                                            aria-activedescendant="listbox-option-3"
                                            className="py-1 overflow-auto text-base ring-1 ring-black ring-opacity-5 max-h-60 rounded-md shadow-xs focus:outline-none sm:text-sm"
                                        >
                                            <li
                                                onClick={() => handleRoleSelection('Monthly')}
                                                className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                            >
                                                <div className="flex items-center">
                                                    <span className="ml-3 block font-normal truncate">Monthly</span>
                                                </div>
                                            </li>
                                            <li
                                                onClick={() => handleRoleSelection('Quartly')}
                                                className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                            >
                                                <div className="flex items-center">
                                                    <span className="ml-3 block font-normal truncate">Quartly</span>
                                                </div>
                                            </li>
                                            <li
                                                onClick={() => handleRoleSelection('Annualy')}
                                                className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                            >
                                                <div className="flex items-center">
                                                    <span className="ml-3 block font-normal truncate">Annualy</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className="mb-4">
                                <div className="mb-4">
                                    <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                                        Price
                                    </label>
                                    <input
                                        type="number"
                                        id="price"
                                        name="price"
                                        value={form.price}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        required
                                        disabled={viewMode} // Disable input in view mode
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="mobileNo" className="block text-sm font-medium text-gray-700">
                                        Mobile No
                                    </label>
                                    <input
                                        type="text"
                                        id="mobileNo"
                                        name="mobileNo"
                                        value={form.mobileNo}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        required
                                        disabled={viewMode} // Disable input in view mode
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="categoryId" className="block text-sm font-medium text-gray-700">
                                        Category
                                    </label>
                                    <select
                                        id="categoryId"
                                        name="categoryId"
                                        value={form.categoryId}
                                        onChange={handleInputChange}
                                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        required
                                        disabled={viewMode} // Disable input in view mode
                                    >
                                        <option value="">Select a category</option>
                                        {categories.map((category) => (
                                            <option key={category.categoryId} value={category.categoryId}>{category.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <label htmlFor="size" className="block text-sm font-medium text-gray-700">
                                    Size (WxH)
                                </label>
                                <input
                                    type="text"
                                    id="size"
                                    name="size"
                                    value={form.size}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder='e.g 20x20'
                                    required
                                    disabled={viewMode} // Disable input in view mode
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="trafficComingFrom" className="block text-sm font-medium text-gray-700">
                                    Trafic Coming From
                                </label>
                                <input
                                    type="text"
                                    id="trafficComingFrom"
                                    name="trafficComingFrom"
                                    value={form.trafficComingFrom}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder='e.g Jinah hospital to Careem Market'
                                    required
                                    disabled={viewMode} // Disable input in view mode
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="trafficGoingTowards" className="block text-sm font-medium text-gray-700">
                                    Trafic Going Towards
                                </label>
                                <input
                                    type="text"
                                    id="trafficGoingTowards"
                                    name="trafficGoingTowards"
                                    value={form.trafficGoingTowards}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder='e.g Careem Market to Jinah Hospital'
                                    required
                                    disabled={viewMode} // Disable input in view mode
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                                    Description
                                </label>
                                <textarea
                                    id="description"
                                    name="description"
                                    value={form.description}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    required
                                    disabled={viewMode} // Disable input in view mode
                                />
                            </div>
                            <div class="mb-4">
                                <label for="date" class="block text-sm font-medium text-gray-700">
                                    Select a date
                                </label>
                                <input
                                    type="date"
                                    id="availabiltyDate"
                                    name="availabiltyDate"
                                    onChange={handleInputChange}
                                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="image" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Image (only .jpg & .png)
                                </label>
                                <input
                                    type="file"
                                    id="image"
                                    name="image"
                                    ref={fileInputRef}
                                    disabled={viewMode}
                                    accept=".jpg,.jpeg,.png"
                                    required
                                    className="mt-2 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:text-white dark:border-gray-600"
                                />
                            </div>
                            <div className="mb-4">
                                <input
                                    type="text"
                                    id="location"
                                    name="location"
                                    value={form.location}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    required
                                    disabled={viewMode} // Disable input in view mode
                                />
                                <button
                                    type="button"
                                    onClick={getCurrentLocation}
                                    className="ml-2 bg-blue-500 text-white px-4 py-2 rounded"
                                    disabled={viewMode} // Disable button in view mode
                                >
                                    Get Current Location
                                </button>
                            </div>
                            <div className="flex justify-end">
                                {selectedProduct ? (
                                    <button
                                        type="button"
                                        onClick={toggleModal}
                                        className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                                    >
                                        Cancel
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        onClick={toggleModal}
                                        className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                                    >
                                        Close
                                    </button>
                                )}
                                <button
                                    type="submit"
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                    disabled={viewMode} // Disable submit button in view mode
                                >
                                    {selectedProduct ? 'Update' : 'Add'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InactiveAds;
