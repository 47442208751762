import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import AdminFooter from '../AdmFooter';
import SubTable from './SubTable';
import logo from '../../../images/header-logo.png';
import { MdDashboard } from "react-icons/md";
import { IoMdEyeOff } from "react-icons/io";
import { MdGppGood } from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { MdManageAccounts } from "react-icons/md";
import '../../../App.css';
import { IoMdArchive } from "react-icons/io";
import Cookies from 'js-cookie';

const SubCategory = () => {
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [totalActiveAds, setTotalActiveAds] = useState(0);
    const [totalNonActiveAds, setTotalNonActiveAds] = useState(0);
    const dropdownRef = useRef(null);
    const sidebarRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutsideDropdown = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideDropdown);
        };
    }, []);

    const handleLogout = () => {
        Cookies.remove('token');
        localStorage.removeItem('email');
        localStorage.removeItem('username');
        localStorage.removeItem('role');
        setUser(null);
        navigate('/login');
    };

    // SideBar Toggle
    const toggleSidebar = () => {
        setIsSidebarOpen(prev => !prev); // Toggle the state
    };

    // SideBar Click Outside
    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsSidebarOpen(false);
        }
    };

    // SideBar Effects
    useEffect(() => {
        const sidebar = sidebarRef.current;
        if (isSidebarOpen) {
            sidebar.classList.remove('-translate-x-full');
        } else {
            sidebar.classList.add('-translate-x-full');
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSidebarOpen]);

    const getToken = () => {
        return Cookies.get('token');
    };

    // API for Active Ads
    useEffect(() => {
        const fetchActiveAdsCount = async () => {
            const token = getToken();
            try {
                const response = await fetch('https://techcogads.com/api/Products/CountActiveProducts', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setTotalActiveAds(data.totalActiveCount);
                } else {
                    console.error('Failed to fetch active ads count');
                }
            } catch (error) {
                console.error('Error fetching active ads count:', error);
            }
        };

        fetchActiveAdsCount();
    }, []);

    // API for NonActive Ads
    useEffect(() => {
        const fetchNonActiveAdsCount = async () => {
            const token = getToken();
            try {
                const response = await fetch('https://techcogads.com/api/Products/CountDeletedProducts', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setTotalNonActiveAds(data.totalInActiveCount);
                } else {
                    console.error('Failed to fetch non-active ads count');
                }
            } catch (error) {
                console.error('Error fetching non-active ads count:', error);
            }
        };

        fetchNonActiveAdsCount();
    }, []);

    return (
        <div className="flex h-screen overflow-hidden font-arapey">
            {/* Sidebar */}
            <aside ref={sidebarRef} id="logo-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen pt-16 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidebar">
                <div className="h-full px-3 pb-4 overflow-y-auto bg-custom-gradient dark:bg-gray-800">
                    <ul className="space-y-2 font-medium mt-4">
                        <li>
                            <NavLink
                                to="/admin/dashboard-panel"
                                className={({ isActive }) =>
                                    isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                }
                            >
                                <MdDashboard className="w-5 h-5 text-current transition duration-75 group-hover:text-black dark:text-gray-400 dark:group-hover:text-white" />
                                <span className="ms-3">Dashboard</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/admin/categories"
                                className={({ isActive }) =>
                                    isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                }
                            >
                                <svg className="flex-shrink-0 w-5 h-5 transition duration-75 group-hover:text-black dark:text-white dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 21">
                                    <path d="M15 12a1 1 0 0 0 .962-.726l2-7A1 1 0 0 0 17 3H3.77L3.175.745A1 1 0 0 0 2.208 0H1a1 1 0 0 0 0 2h.438l.6 2.255v.019l2 7 .746 2.986A3 3 0 1 0 9 17a2.966 2.966 0 0 0-.184-1h2.368c-.118.32-.18.659-.184 1a3 3 0 1 0 3-3H6.78l-.5-2H15Z" />
                                </svg>
                                <span className="flex-1 ms-3 whitespace-nowrap">Categories</span>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink
                                to="/admin/sub-category"
                                className={({ isActive }) =>
                                    isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                }
                            >
                                <svg className="flex-shrink-0 w-5 h-5 text-current transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                    <path d="M14 7h4L9 20l-3-7H0L9 0l3 7Z" />
                                </svg>
                                <span className="flex-1 ms-3 whitespace-nowrap">Sub-Category</span>
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink
                                to="/admin/products"
                                className={({ isActive }) =>
                                    isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                }
                            >

                                <MdOutlineProductionQuantityLimits className="w-5 h-5 text-current transition duration-75 group-hover:text-black dark:text-gray-400 dark:group-hover:text-white" />
                                <span className="flex-1 ms-3 whitespace-nowrap">Products</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/admin/archive-ads"
                                className={({ isActive }) =>
                                    isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                }
                            >
                                <IoMdArchive className="w-5 h-5 text-current transition duration-75 group-hover:text-black dark:text-gray-400 dark:group-hover:text-white" />
                                <span className="flex-1 ms-3 whitespace-nowrap">Archive Posts</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/admin/role-management"
                                className={({ isActive }) =>
                                    isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                }
                            >
                                <MdManageAccounts className="w-5 h-5 text-current transition duration-75 group-hover:text-black dark:text-gray-400 dark:group-hover:text-white" />
                                <span className="flex-1 ms-3 whitespace-nowrap">Role Management</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/admin/featured-ads"
                                className={({ isActive }) =>
                                    isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                }
                            >
                                <svg className="flex-shrink-0 w-5 h-5 text-current transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                    <path d="M14 7h4L9 20l-3-7H0L9 0l3 7Z" />
                                </svg>
                                <span className="flex-1 ms-3 whitespace-nowrap">Featured Ads</span>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink
                                to="/admin/events"
                                className={({ isActive }) =>
                                    isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                }
                            >
                                <svg className="flex-shrink-0 w-5 h-5 text-current transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 16">
                                    <path d="m16 0-4.8 4H0v8h2v4l4.8-4H18V0h-2Zm0 10H6l-2.8 2.4v-2.4H2V6h10.8l2.8-2.4v6.4Z" />
                                </svg>
                                <span className="flex-1 ms-3 whitespace-nowrap">Events</span>
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink
                                to="/admin/user-settings"
                                className={({ isActive }) =>
                                    isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                }
                            >
                                <svg className="flex-shrink-0 w-5 h-5 text-current transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 16">
                                    <path d="M12 7a5 5 0 1 0-6 4.9V16H4a2 2 0 0 0-2 2v1h12v-1a2 2 0 0 0-2-2h-2v-4.1A5.002 5.002 0 0 0 12 7Z" />
                                    <path d="M15 2v6h3v8h-4v2h6V8h-3V2h-2Z" />
                                </svg>
                                <span className="flex-1 ms-3 whitespace-nowrap">User Settings</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </aside>

            {/* Right Section (Main Content) */}
            <main className="flex-1 overflow-y-auto bg-gray-100 dark:bg-gray-900">
                {/* Navbar */}
                <nav className="fixed top-0 z-50 w-full bg-custom-gradient border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                    <div className="px-3 py-3 lg:px-5 lg:pl-3">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center justify-start rtl:justify-end">
                                <button onClick={toggleSidebar} data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                                    <span className="sr-only">Open sidebar</span>
                                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                                    </svg>
                                </button>
                                <a href="/" className="flex ms-8 md:me-24">
                                    <img src={logo} className="h-11" alt="Pak Hoarding Logo" />
                                </a>
                            </div>
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    className="flex text-sm bg-custom-gradient rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                                    onClick={toggleDropdown}
                                >
                                    <span className="sr-only">Open user menu</span>
                                    <FaRegUserCircle className="w-8 h-8 text-gray-400" />
                                </button>
                                {isDropdownOpen && (
                                    <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-20 dark:bg-gray-800">
                                        {/* <NavLink
                                            to="/profile"
                                            className={({ isActive }) =>
                                                isActive ? 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-600' : 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600'
                                            }
                                        >
                                            Your Profile
                                        </NavLink> */}
                                        <button
                                            onClick={handleLogout}
                                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600"
                                        >
                                            Sign out
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </nav>

                {/* Main Content */}
                {/* First Section */}
                <div className="xl:ml-64 lg:ml-64 md:ml-64 p-5 mt-10 bg-white">
                    <div className="p-3 shadow-lg rounded-lg">
                        <div className="flex flex-wrap justify-center py-8 gap-8">
                            <div
                                className="transform rounded-xl h-32 w-64 sm:h-36 sm:w-72 shadow-xl transition duration-300 hover:scale-105
    bg-gradient-to-r from-sky-500 to-emerald-500 to-90% ..."
                            >
                                <div className="flex h-full justify-center items-center gap-4">
                                    <MdGppGood className='text-xl text-white' />
                                    <span className="font-bold text-white text-md">Active Ads</span>
                                    <span className='font-bold text-white text-md'>{totalActiveAds}</span>
                                </div>
                            </div>

                            <div
                                className="transform rounded-xl h-32 w-64 sm:h-36 sm:w-72 shadow-xl transition duration-300 hover:scale-105
    bg-gradient-to-r from-indigo-500 via-20% to-pink-500 ..."
                            >
                                <div className="flex h-full justify-center items-center gap-4">
                                    <IoMdEyeOff className='text-xl text-white' />
                                    <span className="font-bold text-white text-md">Non-Actve Ads</span>
                                    <span className='font-bold text-white text-md'>{totalNonActiveAds}</span>
                                </div>
                            </div>

                            <div
                                className="transform rounded-xl h-32 w-64 sm:h-36 sm:w-72 shadow-xl transition duration-300 hover:scale-105
    bg-gradient-to-r to-cyan-500 from-indigo-500 ..."
                            >
                                <div className="flex h-full justify-center items-center gap-4">
                                    <MdOutlineWorkspacePremium className='text-xl text-white' />
                                    <span className="font-bold text-white text-md">Featured Ads</span>
                                    <span className="font-bold text-white text-md">0</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Category Table */}
                <div className="xl:ml-64 p-5 mt-5 bg-white shadow-lg rounded-lg">
                    <SubTable />
                </div>

                {/* Admin Footer */}
                <div className="bg-custom-gradient dark:bg-custom-gradient text-white p-5 text-center mt-auto xl:ml-56 lg:ml-56 md:ml-56">
                    <AdminFooter />
                </div>
            </main>
        </div>
    );
};

export default SubCategory;
