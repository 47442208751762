import React from 'react';
import { Link } from "react-router-dom";

const SideMenu = () => {
    return (
        <div className="fixed top-1/2 right-0 transform -translate-y-1/2 flex flex-col items-center bg-blue-600 p-2 rounded-l-lg shadow-lg z-50 gap-2">
            <Link to="/add-post" className="flex flex-col items-center text-white text-md font-medium">
                <div className="flex items-center justify-center bg-white rounded-full p-2">
                    {/* Custom Plus Sign Icon */}
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path d="M12 4v16M4 12h16" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <span>Post Ad</span>
            </Link>
        </div>
    );
}

export default SideMenu;
