import React from 'react';
import { Link } from "react-router-dom";

const PostAd = () => {
  return (
    <div className="flex justify-center items-center mt-20 px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 bg-transparent border-2 rounded w-full max-w-4xl p-6 relative">

        {/* Left Column - Original Content */}
        <div className="flex flex-col justify-center items-center">
          <h4 className="text-center mb-[30px] font-bold text-[16.8px] text-blue-600">
          Your Ad, Your way! Let’s Make It Happen
          </h4>
         
          <ul className="space-y-3 text-left">
            <li className="flex items-start justify-start">
              <span className="text-green-500 mr-2">
                {/* Check Icon */}
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a1 1 0 01-.707-.293l-5-5a1 1 0 111.414-1.414L10 15.586l8.293-8.293a1 1 0 111.414 1.414l-9 9A1 1 0 0110 18z" clipRule="evenodd" />
                </svg>
              </span>
              <p className="text-gray-700 text-start">Quickly Register on Pakhoardings</p>
            </li>
            <li className="flex items-start justify-center">
              <span className="text-green-500 mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a1 1 0 01-.707-.293l-5-5a1 1 0 111.414-1.414L10 15.586l8.293-8.293a1 1 0 111.414 1.414l-9 9A1 1 0 0110 18z" clipRule="evenodd" />
                </svg>
              </span>
              <p className="text-gray-700">Select Your Role – Advertiser or User</p>
            </li>
            <li className="flex items-start justify-start">
              <span className="text-green-500 mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a1 1 0 01-.707-.293l-5-5a1 1 0 111.414-1.414L10 15.586l8.293-8.293a1 1 0 111.414 1.414l-9 9A1 1 0 0110 18z" clipRule="evenodd" />
                </svg>
              </span>
              <p className="text-gray-700">Submit Your Ad Effortlessly</p>
            </li>
          </ul>
          <Link to='/add-post' className="bg-red-600 hover:bg-red-700 text-white py-2 text-center px-4 rounded w-full mt-8">
            Post Your Ad Now
          </Link>
        </div>

        {/* Vertical Divider */}
        <div className="hidden md:block h-full w-[1px] bg-gray-300 absolute left-1/2 top-0 transform -translate-x-1/2"></div>

        {/* Right Column - Matching Style with Left Column */}
        <div className="flex flex-col justify-center items-center">
          <h4 className="text-center mb-[30px] font-bold text-[16.8px] text-blue-600">
            Why Advertise With Us?
          </h4>
         
          <ul className="space-y-3 text-left">
            <li className="flex items-start justify-start">
              <span className="text-green-500 mr-2">
                {/* Check Icon */}
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a1 1 0 01-.707-.293l-5-5a1 1 0 111.414-1.414L10 15.586l8.293-8.293a1 1 0 111.414 1.414l-9 9A1 1 0 0110 18z" clipRule="evenodd" />
                </svg>
              </span>
              <p className="text-gray-700 text-start">Wide audience reach</p>
            </li>
            <li className="flex items-start justify-start">
              <span className="text-green-500 mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a1 1 0 01-.707-.293l-5-5a1 1 0 111.414-1.414L10 15.586l8.293-8.293a1 1 0 111.414 1.414l-9 9A1 1 0 0110 18z" clipRule="evenodd" />
                </svg>
              </span>
              <p className="text-gray-700">Affordable and flexible packages</p>
            </li>
            <li className="flex items-start justify-start">
              <span className="text-green-500 mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a1 1 0 01-.707-.293l-5-5a1 1 0 111.414-1.414L10 15.586l8.293-8.293a1 1 0 111.414 1.414l-9 9A1 1 0 0110 18z" clipRule="evenodd" />
                </svg>
              </span>
              <p className="text-gray-700">
                Strategic spots for peak exposure</p>
            </li>
          </ul>
          <Link to='/about-us' className="bg-blue-600 hover:bg-blue-700 text-white py-2 text-center px-4 rounded w-full mt-8">
            Post Your Ad Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PostAd;
